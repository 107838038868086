import { styled, Input } from '@innowise-group/mui-kit';
import { NumberInput } from '@shared-mui-components';

export const DisabledInput = styled(Input)``;

export const FullNumberInput = styled(NumberInput)`
  &.MuiTextField-root {
    width: 100% !important;
  }
`;

export const DatePickerContainer = styled.div`
  & .MuiTextField-root {
    width: 100% !important;
  }
  & .MuiOutlinedInput-root {
    padding-right: 2px;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  flex: 1;
  gap: 1rem;
  row-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const VacancyContainer = styled.div`
  grid-column: 2 / -1;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
  flex: 1;
`;

export const CheckboxesColumn = styled(ColumnContainer)`
  gap: 1.5rem;
  flex: 1;
`;

export const FieldsGroup = styled(ColumnContainer)`
  flex: 0;
`;

export const GroupLabel = styled.span`
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  ${({ theme }) => theme.fonts.primary[60]}
`;

export const CommentContainer = styled.div`
  grid-column: 1 / -1;
`;
