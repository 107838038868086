import { Switch as BaseSwitch, Input, Button as BaseButton, Icon, styled } from '@innowise-group/mui-kit';

export const FormContainer = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.general.lightGrey[60]};
`;

export const MainForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const BlockTitle = styled.div`
  margin-bottom: 0.75rem;
  ${({ theme }) => theme.fonts.primary[60]}
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

export const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const SubStatusesContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

export const SubStatusesFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ComponentsBlock = styled(StatusContainer)``;

export const SubStatusBlockTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  margin-bottom: 1rem;
`;

export const ComponentsBlockTitle = styled(SubStatusBlockTitle)`
  margin: 0.75rem 0;
`;

export const ComponentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const Switch = styled(BaseSwitch)`
  &.MuiSwitch-root {
    transform: scale(0.8);
  }
`;

export const SubStatusRow = styled.div`
  display: flex;
  gap: 0.75rem;
`;

export const SubStatusInput = styled(Input)``;

export const DeleteIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.palette.state.error.pressed};
    transition: 0.2s;
    opacity: ${({ disabled }) => disabled && 0.4};
  }

  &:hover svg {
    fill: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
  }
`;

export const ArchiveIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
    transition: 0.2s;
    opacity: ${({ disabled }) => disabled && 0.4};
  }

  &:hover svg {
    fill: ${({ theme, disabled }) => !disabled && theme.palette.state.error.default};
  }
`;

export const Button = styled(BaseButton)`
  &.MuiButton-root.MuiButton-text,
  &.MuiButton-root.MuiButton-outlined,
  &.MuiButton-root.MuiButton-contained {
    width: fit-content;
  }

  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
    }
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    & svg {
      fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
    }
  }
`;

export const DeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 4rem;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: auto;
`;
