import { LocalizedEntity } from '@innowise-group/core';
import getLanguageFromLS from './get-language-from-ls.utility';
import { AppLanguages } from '@constants';

export const localizedNameObject = (
  values: LocalizedEntity,
): { localizedFullName: string; localizedFirstName: string; localizedLastName: string } => {
  const lang = getLanguageFromLS();

  const candidateFullNameMap: { [key: string]: string } = {
    [AppLanguages.En]: values.firstNameEn
      ? `${values.lastNameEn} ${values.firstNameEn}`
      : `${values.lastNameRu} ${values.firstNameRu}`,
    [AppLanguages.Ru]: values.firstNameRu
      ? `${values.lastNameRu} ${values.firstNameRu}`
      : `${values.lastNameEn} ${values.firstNameEn}`,
  };

  const candidateNameMap: { [key: string]: { lastName: string; firstName: string } } = {
    [AppLanguages.En]: {
      lastName: values.lastNameEn || values.lastNameRu,
      firstName: values.firstNameEn || values.firstNameRu,
    },
    [AppLanguages.Ru]: {
      lastName: values.lastNameRu || values.lastNameEn,
      firstName: values.firstNameRu || values.firstNameEn,
    },
  };

  return {
    localizedFullName: candidateFullNameMap[lang],
    localizedFirstName: candidateNameMap[lang].firstName,
    localizedLastName: candidateNameMap[lang].lastName,
  };
};
