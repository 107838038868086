import { StatusLifecycleStatuses } from '@constants';
import { AdministrationStatusItem } from '@innowise-group/core';

export const defaultStatusCreationFormValues: AdministrationStatusItem = {
  statusName: { RUSSIAN: '', ENGLISH: '' },
  subStatusNames: [{ id: '', names: { RUSSIAN: '', ENGLISH: '' }, lifecycleStatus: StatusLifecycleStatuses.Actual }],
  deletedSubStatusNames: [],
  group: '',
  VACANCY: {
    isExist: false,
    isMandatory: false,
  },
  SUB_STATUS: {
    isExist: false,
    isMandatory: false,
  },
  REMINDER: {
    isExist: false,
    isMandatory: false,
  },
  CALENDAR: {
    isExist: false,
    isMandatory: false,
  },
  FILE: {
    isExist: false,
    isMandatory: false,
  },
  LINK: {
    isExist: false,
    isMandatory: false,
  },
  NOTIFICATION: {
    isExist: false,
    isMandatory: false,
  },
  AGREEMENT: {
    isExist: false,
    isMandatory: false,
  },
  FEEDBACK: {
    isExist: false,
    isMandatory: false,
  },
};

export const components: {
  title: string;
  fields: {
    name: keyof Omit<AdministrationStatusItem, 'subStatusNames' | 'deletedSubStatusNames' | 'group' | 'statusName'>;
    label: string;
  }[];
}[] = [
  {
    title: '',
    fields: [
      { name: 'VACANCY', label: 'pages.administration.statuses.vacancyCell' },
      { name: 'SUB_STATUS', label: 'pages.administration.statuses.subStatusCell' },
    ],
  },
  {
    title: 'pages.administration.statuses.addButtons',
    fields: [
      { name: 'REMINDER', label: 'pages.administration.statuses.reminder' },
      { name: 'CALENDAR', label: 'pages.administration.statuses.calendar' },
    ],
  },
  {
    title: 'pages.administration.statuses.attach',
    fields: [
      { name: 'FILE', label: 'pages.administration.statuses.fileCell' },
      { name: 'LINK', label: 'pages.administration.statuses.linkCell' },
    ],
  },
  {
    title: 'pages.administration.statuses.interestedPerson',
    fields: [
      { name: 'NOTIFICATION', label: 'pages.administration.statuses.notification' },
      { name: 'AGREEMENT', label: 'pages.administration.statuses.agreement' },
      { name: 'FEEDBACK', label: 'pages.administration.statuses.feedback' },
    ],
  },
];
