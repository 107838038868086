import { styled, css } from '@innowise-group/mui-kit';
import { Input as BaseInput } from '@innowise-group/mui-kit';
import { AnimationWrapper } from '../shared';

const ContainerStyled = css`
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.general.paper};
`;

export const Container = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  left: 10px;
  max-width: 75%;
  top: -2px;
  height: 37px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
`;

export const FirstSelectedValue = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectedCount = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  padding: 3px 5px;
  margin-left: 5px;
  border-radius: 50%;
  flex-shrink: 0;
`;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
  .MuiPaper-root {
    height: fit-content;
  }
`;

export const FilterWrapper = styled.div`
  ${ContainerStyled}
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-end;
  height: fit-content;
`;

export const ListItemContiner = styled.div`
  ${ContainerStyled}
  width: 100%;
  max-height: 205px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Input = styled(BaseInput)`
  width: 225px;
`;

export const VacancySearchInput = styled(BaseInput)`
  & .MuiOutlinedInput-root {
    width: 210px;
    background: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.general.darkGrey[20]};
  }
  & .MuiFormHelperText-root {
    display: none;
  }

  &.MuiTextField-root {
    margin-top: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  margin-left: auto;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InputLabel = styled.span``;

export const CheckboxOptionItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  gap: 15px;
  padding: 9px 16px;
  ${({ theme }) => theme.fonts.primary[20]};
  background-color: transparent !important;
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]} !important;
  }
`;
