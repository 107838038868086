import {
  Button,
  Icon,
  Loader,
  Modal,
  ModalProps,
  modalHoc,
  notificationsManager,
  useModal,
} from '@innowise-group/mui-kit';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './offer-edition.styles';
import { GeneratedOfferDocument, OfferForm, defaultOfferFormValues } from '@shared-components';
import {
  CandidateOfferItem,
  FileIdResponse,
  useCandidateFilesAPI,
  useCandidateOffersAPI,
  useCandidatesOffersFormData,
  useCurrentCandidate,
  useOptionsApi,
  useUnitsAdministrationOptions,
  useValidationSchemaOffer,
} from '@innowise-group/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilePreviewModal } from '@innowise-group/modals';
import { DateFormats, MaxFileSize, OfferFilesAcceptFormats, OfferFilesFormats } from '@constants';
import { pdf } from '@react-pdf/renderer';
import { format } from 'date-fns';

interface OfferEditionModalProps {
  id: string;
}

const OfferEditionModal: React.FC<ModalProps & OfferEditionModalProps> = ({ close, id }) => {
  const { t } = useTranslation();
  const { isFormLoading, updateOffer, items } = useCandidateOffersAPI();
  const currentCandidate = useCurrentCandidate();
  const currentOffer = useCandidatesOffersFormData(id);
  const { countriesOptions, officeOptions, locationOptions, jobTitleOptions } = useOptionsApi();
  const unitsOptions = useUnitsAdministrationOptions();
  const { isFilesIdsLoading, createFileId } = useCandidateFilesAPI();
  const { validationSchema } = useValidationSchemaOffer();
  const [file, setFile] = useState<FileIdResponse>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showFilePreview] = useModal(FilePreviewModal);

  const methods = useForm<CandidateOfferItem>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultOfferFormValues,
  });

  const { handleSubmit, reset, watch, setValue, getValues, trigger } = methods;
  const offerType = watch('offerType');
  const offerDate = watch('offerDate');

  const fileName = `${offerType}_${currentCandidate.firstNameEn}_${currentCandidate.lastNameEn}_${format(
    new Date(offerDate),
    DateFormats.DayFirst,
  )}.pdf`;

  const checkValidation = async (event?: React.MouseEvent<HTMLButtonElement>) => {
    trigger();
    return validationSchema
      .validate(getValues(), { abortEarly: false, recursive: true })
      .then(() => {
        return true;
      })
      .catch((err) => {
        const errorsArrays = err.inner?.filter((item) => !item.path.includes('fileId')) || [];
        if (errorsArrays.length) {
          event?.preventDefault();
          event?.stopPropagation();
        }
        return !errorsArrays.length;
      });
  };

  const onSubmit = async (data: CandidateOfferItem) => {
    await updateOffer({ ...data, candidateId: currentCandidate.id });
    close();
  };

  const openPreviewFile = (file: FileIdResponse) => {
    showFilePreview({ fileName, id: file.id, insideModal: true });
  };

  const generateFile = async () => {
    const isValid = await checkValidation();
    if (!isValid) return;
    const blob = await pdf(
      <GeneratedOfferDocument
        candidateName={currentCandidate.localizedFirstName}
        {...getValues()}
        unitsOptions={unitsOptions}
        jobTitleOptions={jobTitleOptions}
        officeOptions={officeOptions}
        citiesOptions={locationOptions}
        countryOptions={countriesOptions}
      />,
    ).toBlob();
    const generatedFile = new File([blob], fileName, {
      type: 'application/pdf',
    });
    const file = await createFileId(generatedFile, true, openPreviewFile);
    setValue('fileId', file.id);
    setFile(file);
  };

  const handleDeleteFile = async (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.stopPropagation();
    if (file) {
      setValue('fileId', null);
      setFile(null);
    }
  };

  const handleOpenFile = () => {
    showFilePreview({
      fileName: file.filename,
      id: file.id,
      insideModal: true,
    });
  };

  const addInputFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileType = event.target.files[0].type || event.target.files[0].name.split('.').reverse()[0];
    if (event.target.files[0] && event.target.files[0].size <= MaxFileSize && OfferFilesFormats.includes(fileType)) {
      try {
        const fileResponse = await createFileId(event.target.files[0], true);
        setFile(fileResponse);
        setValue('fileId', fileResponse.id);
        fileInputRef.current.value = null;
      } catch {
        setFile(null);
        fileInputRef.current.value = null;
      }
    } else {
      notificationsManager.error({
        subtitle: t(
          `notifications.text.${
            !(event.target.files[0]?.size <= MaxFileSize) ? 'invalidFileSize' : 'invalidFileFormat'
          }`,
        ),
      });
      fileInputRef.current.value = null;
      setFile(null);
    }
  };

  useEffect(() => {
    if (currentOffer) {
      reset(currentOffer);
      setFile(items[id].file);
    }
  }, [reset, currentOffer?.offerType, items, id]);

  return (
    <Modal.Container size="large">
      <Modal.Header onClose={close}>
        <Styled.Header>{t('pages.candidates.offer.offerEdition')}</Styled.Header>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          {isFormLoading ? <Loader /> : <OfferForm disabled={!!file || isFilesIdsLoading} />}
        </FormProvider>
      </Modal.Body>
      <Styled.Footer>
        {!isFormLoading && !!file && (
          <Styled.FileItem onClick={handleOpenFile}>
            {file.secured && (
              <Styled.SecureFileIconContainer>
                <Icon type="u_lock_alt" />
              </Styled.SecureFileIconContainer>
            )}
            <span>{file.filename}</span>
            <Styled.FileButtonContainer>
              <Icon type="u_plus" onClick={handleDeleteFile} />
            </Styled.FileButtonContainer>
          </Styled.FileItem>
        )}
        {!file && (
          <React.Fragment>
            <Styled.GrayButton
              variant="outlined"
              onClick={checkValidation}
              disabled={isFormLoading}
              startIcon={<Icon type="u_export" size={12} viewBox="3 3 20 20" />}
            >
              {t('buttons.uploadProposal')}
              <Styled.FileInput
                type="file"
                onChange={addInputFile}
                ref={fileInputRef}
                accept={OfferFilesAcceptFormats.join(', ')}
                disabled={isFormLoading}
              />
            </Styled.GrayButton>
            <Styled.FileButton
              variant="outlined"
              onClick={generateFile}
              disabled={isFilesIdsLoading}
              startIcon={<Icon type="u_plus" size={12} viewBox="2 2 18 18" />}
            >
              {t('buttons.makeOffer')}
            </Styled.FileButton>
          </React.Fragment>
        )}
        <Styled.GrayButton onClick={close} variant="outlined" disabled={isFormLoading}>
          {t('buttons.cancel')}
        </Styled.GrayButton>
        {!!file && (
          <Button onClick={handleSubmit(onSubmit)} disabled={isFormLoading}>
            {t('buttons.save')}
          </Button>
        )}
      </Styled.Footer>
    </Modal.Container>
  );
};

export default modalHoc(OfferEditionModal, 'offer-edition');
