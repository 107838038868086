import { styled } from '@innowise-group/mui-kit';
import { appearAnimation } from '../global-styles';

export const DropdownListItem = styled.button<{
  readonly?: boolean;
  selected?: boolean;
  noPadding?: boolean;
  isHeader?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 9px 0 10px')};
  text-align: left;
  align-items: center;
  min-height: 30px;
  width: 100%;
  font-size: ${({ isHeader }) => (isHeader ? '0.75rem' : '0.875rem')};
  user-select: ${({ readonly = false }) => (readonly ? 'none' : 'initial')};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.general.darkGrey[40] : theme.palette.general.darkGrey[100]};
  transition: 0.2s;
  overflow: hidden;
  background-color: ${({ theme, selected }) => (selected ? theme.palette.state.error.background : 'initial')};
  border: none;
  cursor: ${({ readonly = false, disabled }) => (readonly || disabled ? 'default' : 'pointer')};
  &:hover {
    background-color: ${({ theme, readonly = false, disabled, selected }) =>
      readonly || disabled
        ? 'inherit'
        : selected
        ? theme.palette.state.error.background
        : theme.palette.general.darkGrey[20]};
  }
  &:last-child {
    margin: 0;
  }
`;

export const DropdownContainer = styled.div<{ size?: 'default' | 'small' | 'extra-small' | 'large' }>`
  position: relative;
  outline: none;
  box-sizing: border-box;
  width: ${({ size }) =>
    size === 'large'
      ? '260px'
      : size === 'default'
      ? '190px'
      : size === 'small'
      ? '160px'
      : size === 'extra-small'
      ? '70px'
      : '100%'} !important;
  margin: 0 20px 0 0;
`;

interface DropdownHeaderProps {
  isFocused: boolean;
  disabled: boolean;
  error?: boolean;
}
export const DropdownHeader = styled.div<DropdownHeaderProps>`
  position: relative;
  box-sizing: border-box;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  border: 2px solid
    ${({ theme, error }) => (error ? theme.palette.state.error.default : theme.palette.general.lightGrey[100])};
  border-radius: 10px;
  padding: 0 24px 0 10px;
  transition: ${({ theme }) => theme.animation.transition};
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fonts.primary[20]}
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  min-height: 40px;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border-color: ${({ theme, disabled, error }) =>
      error ? theme.palette.state.error.default : !disabled && theme.palette.general.darkGrey[40]};
  }

  &:focus {
    border-color: ${({ theme, disabled, error }) =>
      error ? theme.palette.state.error.default : !disabled && theme.palette.general.darkGrey[40]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.general.lightGrey[100]};
  }
`;

export const DropdownListContainer = styled.div<{ vertical: string; position: 'absolute' | 'fixed' | 'static' }>`
  margin: 4px 0;
  max-height: 210px;
  width: inherit;
  box-sizing: border-box;
  position: ${({ position }) => position};
  bottom: ${({ vertical }) => (vertical === 'top' ? '100%' : 'default')};
  z-index: 10000;
  background-color: ${({ theme }) => theme.palette.general.paper};
  border-radius: 6px 4px 4px 6px;
  overflow: hidden auto;
  border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[100]};
  animation: ${appearAnimation} 0.175s ease-in-out;
  padding: 3px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.general.lightGrey[40]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.general.lightGrey[60]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.general.lightGrey[80]};
  }
`;

export const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
`;
