import { styled } from '@innowise-group/mui-kit';
import { DatePicker as BaseDatePicker } from '@shared-mui-components';
import { AnimationWrapper } from '../shared';

export const Wrapper = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstSelectedValue = styled.p``;

export const PlaceDescription = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 3px;
  padding: 3px 5px;
  margin-left: 5px;
`;

export const DatePicker = styled(BaseDatePicker)`
  width: 100%;
`;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
`;

export const FilterWrapper = styled.div`
  background: ${({ theme }) => theme.palette.general.paper};
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  padding: 15px;
  border-radius: 6px;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.div`
  ${({ theme }) => theme.fonts.primary[20]};
  margin-bottom: 3px;
`;
