import {
  BaseFilterType,
  EmployeeFilterType,
  EmployeesService,
  ShortEmployeeItemResponse,
  useDebounce,
} from '@innowise-group/core';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './employe.styles';
import { FilterButton } from '../shared';
import { Loader, DropdownListItem } from '@innowise-group/mui-kit';

interface EmployeFilterProps extends Pick<BaseFilterType, 'labelKey' | 'isOpen'> {
  state: EmployeeFilterType['state'];
  pinned?: boolean;
  onValueChange: (employeId: EmployeeFilterType['state']) => void;
  onToggleFilter: () => void;
  onRemoveFilter: (state: EmployeeFilterType['state']) => void;
  onClickFilterOutside: () => void;
}

const EmployeFilter: FC<EmployeFilterProps> = ({
  state,
  labelKey,
  isOpen,
  pinned,
  onValueChange,
  onToggleFilter,
  onRemoveFilter,
  onClickFilterOutside,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [employees, setEmployees] = useState<ShortEmployeeItemResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentEmploye, setCurrentEmploye] = useState<ShortEmployeeItemResponse | null>(null);
  const [debouncedValue] = useDebounce(search);

  const getEmployees = useCallback(
    async (partOfName: string) => {
      setIsLoading(true);
      try {
        const { data } = await EmployeesService.searchEmployees({ partOfName, size: 5 });
        setEmployees(data.content);
      } finally {
        setIsLoading(false);
      }
    },
    [EmployeesService.searchEmployees],
  );

  const getCurrentEmployee = useCallback(async (id: number) => {
    setIsLoading(true);
    try {
      const { data } = await EmployeesService.getEmployeeById(id);
      setCurrentEmploye(data);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const [currentEmployeFromState] = state;
    if (currentEmployeFromState) {
      getCurrentEmployee(currentEmployeFromState);
    }
  }, []);

  useEffect(() => {
    if (!state.length) setCurrentEmploye(null);
  }, [state]);

  useEffect(() => {
    if (debouncedValue) {
      getEmployees(debouncedValue);
    } else {
      setEmployees([]);
    }
  }, [debouncedValue, getEmployees]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onRemoveFilterHandler = () => onRemoveFilter([]);

  const onClickFilterOutsideHandle = () => {
    const isEmpty = !state?.length;
    setSearch('');
    if (isEmpty && !pinned) onRemoveFilterHandler();
    onClickFilterOutside();
  };

  useEffect(() => {
    setAnchorEl(isOpen ? ref.current : null);
  }, [isOpen]);

  const onValueChangeHandler = (employee: ShortEmployeeItemResponse) => () => {
    onValueChange([employee.employeeId]);
    setCurrentEmploye(employee);
    setSearch('');
    if (employee.employeeId) onClickFilterOutside();
  };

  return (
    <Styled.Container ref={ref}>
      <FilterButton
        isActive={isOpen}
        action="delete"
        iconSize={16}
        iconViewBox="-1 -1 14 14"
        onToggleFilter={!pinned ? onRemoveFilterHandler : null}
        onClick={onToggleFilter}
      >
        <Styled.SelectedInfoContainer>
          <Styled.FirstSelectedValue>{t(labelKey)}</Styled.FirstSelectedValue>
          {currentEmploye && (
            <Styled.SelectedCount>{`${currentEmploye.firstNameEn} ${currentEmploye.lastNameEn}`}</Styled.SelectedCount>
          )}
        </Styled.SelectedInfoContainer>
      </FilterButton>
      <Styled.CustomAnimationWrapper
        disablePortal={true}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClickFilterOutsideHandle}
      >
        <Styled.FilterWrapper>
          <Styled.Input
            placeholder={t('pages.candidates.candidateCreation.selectTheEmployee')}
            onChange={onInputChangeHandler}
            value={search}
          />
          <Styled.ListItemContiner>
            {!isLoading ? (
              employees.map((employe) => {
                return (
                  <DropdownListItem key={employe.employeeId} onClick={onValueChangeHandler(employe)} selected={false}>
                    {employe.photoId ? (
                      <Styled.Image id={employe.photoId} />
                    ) : (
                      <Styled.NoImageContainer>
                        <Styled.NoImageIcon type="u_camera" size={20} viewBox="0 0 49 44" />
                      </Styled.NoImageContainer>
                    )}
                    <Styled.ItemText>{`${employe.firstNameEn} ${employe.lastNameEn}`}</Styled.ItemText>
                  </DropdownListItem>
                );
              })
            ) : (
              <Loader />
            )}
          </Styled.ListItemContiner>
        </Styled.FilterWrapper>
      </Styled.CustomAnimationWrapper>
    </Styled.Container>
  );
};

export default memo(EmployeFilter);
