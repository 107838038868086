import React, { useMemo } from 'react';
import { useTheme } from '../theme';
import { AppIconsType, APP_ICONS } from './icon.constants';
import * as Styled from './icon.styles';

export interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  type: AppIconsType;
  size?: number;
  fill?: string;
  viewBox?: string;
  disabled?: boolean;
  isPointer?: boolean;
}

const Icon: React.FC<IconProps> = ({ type, size = 20, fill, viewBox = '0 0 24 24', ...props }) => {
  const theme = useTheme();

  const fillIcon = useMemo(() => {
    return fill ? fill : theme.palette.general.darkGrey[60];
  }, [fill, theme]);

  return (
    <Styled.IconContainer size={size} {...props}>
      <svg width={size} height={size} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" fill={fillIcon}>
        {APP_ICONS[type](fillIcon)}
      </svg>
    </Styled.IconContainer>
  );
};

export default Icon;
