import React from 'react';
import * as Styled from './desktop-view.styles';
import { useTranslation } from 'react-i18next';
import { RolesValues, SortingValue } from '@constants';
import { Menu, MenuItem } from '@innowise-group/mui-kit';
import { Tabs } from '@innowise-group/core';

interface DesktopViewProps {
  currentTab: Tabs;
  sort: string;
  tabs: { id: Tabs; name: string }[];
  sortItems: { text: string; value: string }[];
  isRecruiterMode?: boolean;
  myTabRoles: RolesValues[];
  openSortMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeSortMenu: () => void;
  anchorEl: HTMLElement | null;
  selected: number[];
  handleChangeTab: (tabId: Tabs) => () => void;
  setSortParamHandle: (sortItem: string) => (event: React.MouseEvent) => void;
  ExportButtonComponent: React.FC;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  currentTab,
  tabs,
  myTabRoles,
  handleChangeTab,
  isRecruiterMode,
  sort,
  selected,
  closeSortMenu,
  anchorEl,
  openSortMenu,
  sortItems,
  setSortParamHandle,
  ExportButtonComponent,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.CustomTabs value={currentTab}>
      {tabs.map((item) => {
        if (item.id === Tabs.My) {
          return (
            <Styled.CustomTab
              key={item.id}
              label={t(item.name) || ''}
              value={item.id}
              roles={myTabRoles}
              tabsvalue={currentTab}
              {...(currentTab !== item.id && { onClick: handleChangeTab(item.id) })}
            />
          );
        }
        if (item.id === Tabs.All) {
          return (
            <Styled.CustomTab
              key={item.id}
              label={t(item.name) || ''}
              value={item.id}
              tabsvalue={currentTab}
              {...(currentTab !== item.id && { onClick: handleChangeTab(item.id) })}
            />
          );
        }
        if (!isRecruiterMode) {
          return (
            <Styled.CustomTab
              key={item.id}
              label={t(item.name) || ''}
              value={item.id}
              roles={[RolesValues.Admin]}
              tabsvalue={currentTab}
              {...(currentTab !== item.id && { onClick: handleChangeTab(item.id) })}
            />
          );
        }
      })}
      <Styled.TableFilters>
        <ExportButtonComponent />
        <Styled.GrayButton startIcon={<Styled.FilterIcon type="u_filter" />} onClick={openSortMenu} variant="text">
          <span>{t('pages.candidates.allCandidatesTable.sort')}</span>
        </Styled.GrayButton>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeSortMenu}>
          {sortItems.map((item, index) => (
            <MenuItem
              key={index}
              selected={sort === item.value}
              onClick={!selected.length && item.value === SortingValue.Checked ? null : setSortParamHandle(item.value)}
              disabled={!selected.length && item.value === SortingValue.Checked}
            >
              {t(item.text)}
            </MenuItem>
          ))}
        </Menu>
      </Styled.TableFilters>
    </Styled.CustomTabs>
  );
};

export default DesktopView;
