import React, { useMemo } from 'react';
import * as Styled from './footer-section.styles';
import { useTranslation } from 'react-i18next';
import {
  FileIdResponse,
  StatusItemResponse,
  useCandidateStatusAPI,
  useProfileAPI,
  useCandidateEventsAPI,
  useResizeObserver,
} from '@innowise-group/core';
import { useModal } from '@innowise-group/mui-kit';
import { ClaimActionTypes, ClaimStatusTypes, ClaimTypes, ManagerRolesArray, RolesValues } from '@constants';
import { FeedbackModal } from '@innowise-group/modals';
import { RoleGuard } from '@shared-components';
import { Button } from '@innowise-group/mui-kit';
import { calculateDateWithTimeZone } from '@innowise-group/utilities';
import { useTheme } from '@innowise-group/mui-kit';

interface FooterSectionProps {
  openEditionModal: () => void;
  currentEvent: StatusItemResponse;
  candidateId: number;
  handleClose: () => void;
}

const FooterSection: React.FC<FooterSectionProps> = ({ currentEvent, handleClose, openEditionModal, candidateId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.sm);
  const { profile } = useProfileAPI();
  const { resolveClaim } = useCandidateStatusAPI();
  const { events } = useCandidateEventsAPI();
  const [showFeedbackModal, hideFeedbackModal] = useModal(FeedbackModal);

  const isLoading = useMemo(() => events[currentEvent.id].comment.isLoading, [events, currentEvent]);

  const agreementClaim = useMemo(() => {
    const agreementClaim = currentEvent.claims.find(
      (item) => item.claimType === ClaimTypes.AGREEMENT && item.active && item.employee?.employeeId === profile.id,
    );
    return agreementClaim;
  }, [profile?.id, currentEvent]);

  const editAllowed = useMemo(
    () =>
      currentEvent?.status?.id
        ? currentEvent?.author?.employeeId === profile.id || profile.role?.name === RolesValues.Admin
        : currentEvent?.comment?.editAllowed,
    [currentEvent, profile],
  );

  const { isFeedbackEmployee, feedbackClaim, isDisabled } = useMemo(() => {
    const feedbackClaim = currentEvent?.claims.find((item) => item.claimType === ClaimTypes.FEEDBACK && item.active);
    let isDisabled = false;
    if (currentEvent?.eventDate) {
      const eventDate = new Date(calculateDateWithTimeZone(currentEvent.eventDate));
      eventDate.setHours(0, 0, 0, 0);
      isDisabled = currentEvent?.eventDate ? eventDate.getTime() > new Date().getTime() : false;
    }

    return { feedbackClaim, isFeedbackEmployee: feedbackClaim?.employee?.employeeId === profile.id, isDisabled };
  }, [profile?.id, currentEvent]);

  const handleResolveClaim = (actionType: ClaimActionTypes, claimId: number) => () => {
    resolveClaim(currentEvent.id, actionType, claimId);
  };

  const handleFeedback = () => {
    showFeedbackModal({
      status: currentEvent,
      successCallback: successFeedbackCallback,
    });
  };

  const successFeedbackCallback = (file: FileIdResponse) => async () => {
    await resolveClaim(currentEvent.id, ClaimActionTypes.FEEDBACK, feedbackClaim.id, file, candidateId);
    hideFeedbackModal();
  };

  return (
    <Styled.Footer>
      <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, ...ManagerRolesArray]}>
        {isFeedbackEmployee &&
          isDesktopView &&
          (feedbackClaim?.status === ClaimStatusTypes.PENDING ||
            feedbackClaim?.status === ClaimStatusTypes.OVERDUE) && (
            <Styled.FeedBackButton variant="text" onClick={handleFeedback} disabled={isDisabled}>
              <Styled.CustomIcon type="u_feedback" viewBox="0 0 24 24" isPointer={!isDisabled} />
              {t('buttons.giveFeedback')}
            </Styled.FeedBackButton>
          )}
      </RoleGuard>
      {agreementClaim &&
        isDesktopView &&
        (agreementClaim.status === ClaimStatusTypes.PENDING || agreementClaim.status === ClaimStatusTypes.OVERDUE) && (
          <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, ...ManagerRolesArray]}>
            <Button
              disabled={isLoading}
              onClick={handleResolveClaim(ClaimActionTypes.APPROVE, agreementClaim.id)}
              variant="text"
            >
              <Styled.CustomIcon type="u_check_square" viewBox="0 0 24 24" isPointer />
              {t('buttons.agreeOn')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={handleResolveClaim(ClaimActionTypes.DECLINE, agreementClaim.id)}
              variant="text"
            >
              <Styled.CustomIcon type="u_alert_circle" viewBox="0 0 24 24" isPointer />
              {t('buttons.reject')}
            </Button>
          </RoleGuard>
        )}
      {agreementClaim?.status === ClaimStatusTypes.RESOLVED && isDesktopView && (
        <Styled.ClaimStatusItem variant="text" disabled>
          {t('pages.candidates.statuses.approved')}
          <Styled.CustomIcon type="u_check_square" viewBox="0 0 24 24" />
        </Styled.ClaimStatusItem>
      )}
      {agreementClaim?.status === ClaimStatusTypes.DECLINED && isDesktopView && (
        <Styled.ClaimStatusItem variant="text" disabled isDeclined>
          {t('pages.candidates.statuses.declined')}
          <Styled.CustomIcon type="u_alert_circle" viewBox="0 0 24 24" />
        </Styled.ClaimStatusItem>
      )}
      {!isDesktopView && (
        <Styled.CustomButton variant="outlined" onClick={handleClose}>
          {t('buttons.close')}
        </Styled.CustomButton>
      )}
      {editAllowed && (
        <Styled.CustomButton onClick={openEditionModal} variant={isDesktopView ? 'text' : 'contained'}>
          <Styled.CustomIcon type="u_edit" viewBox="0 0 22 22" isPointer />
          {t('buttons.edit')}
        </Styled.CustomButton>
      )}
    </Styled.Footer>
  );
};

export default FooterSection;
