import { MaxSubStatusesCount, StatusLifecycleStatuses } from '@constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const MAX_STATUS_NAME_CHARACTERS = 30;
const MAX_SUB_STATUS_NAME_CHARACTERS = 65;

export const useValidationSchemaAdministrationStatus = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      statusName: Yup.object().shape({
        RUSSIAN: Yup.string()
          .trim()
          .max(MAX_STATUS_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_STATUS_NAME_CHARACTERS }))
          .required(t('errors.requiredField'))
          .matches(/^[a-zA-Zа-яА-ЯЁё-\s]+$/, t('errors.rusStatusLettersCity')),
        ENGLISH: Yup.string()
          .trim()
          .required(t('errors.requiredField'))
          .max(MAX_STATUS_NAME_CHARACTERS, t('errors.maxCharacters', { count: MAX_STATUS_NAME_CHARACTERS }))
          .matches(/^['’a-zA-Z-\s]+$/, t('errors.latinLettersCity')),
      }),
      subStatusNames: Yup.array()
        .of(
          Yup.object()
            .shape({
              names: Yup.object().shape(
                {
                  RUSSIAN: Yup.string()
                    .trim()
                    .max(
                      MAX_SUB_STATUS_NAME_CHARACTERS,
                      t('errors.maxCharacters', { count: MAX_SUB_STATUS_NAME_CHARACTERS }),
                    )
                    .matches(/(^$)|(^[a-zA-Zа-яА-ЯЁё-\s]+$)/, t('errors.rusStatusLettersCity'))
                    .when('ENGLISH', {
                      is: (english: string) => !!english,
                      then: (schema) => schema.required(t('errors.requiredField')),
                    }),
                  ENGLISH: Yup.string()
                    .trim()
                    .max(
                      MAX_SUB_STATUS_NAME_CHARACTERS,
                      t('errors.maxCharacters', { count: MAX_SUB_STATUS_NAME_CHARACTERS }),
                    )
                    .matches(/(^$)|(^['’a-zA-Z-\s]+$)/, t('errors.latinLettersCity'))
                    .when('RUSSIAN', {
                      is: (russian: string) => !!russian,
                      then: (schema) => schema.required(t('errors.requiredField')),
                    }),
                },
                [['ENGLISH', 'RUSSIAN']],
              ),
              lifecycleStatus: Yup.string().required(),
            })
            .test('test', t('errors.valueAlreadyExist'), function (item) {
              const index = parseInt(this.path.replace(/[^\d]/g, ''));
              const duplicates: number[] = [];
              this.parent.map((curr, idx) => {
                if (curr.names.ENGLISH === item.names.ENGLISH && item.names.ENGLISH) {
                  duplicates.push(idx);
                } else {
                  if (curr.names.RUSSIAN === item.names.RUSSIAN && item.names.RUSSIAN) {
                    duplicates.push(idx);
                  }
                }
              });
              return duplicates.length <= 1 || duplicates.findIndex((curr) => curr === index) === 0;
            })
            .test('subStatusesCount', t('errors.maxSubStatusCount', { count: MaxSubStatusesCount }), function () {
              return (
                this.parent.filter((item) => item.lifecycleStatus !== StatusLifecycleStatuses.Deleted).length <=
                MaxSubStatusesCount
              );
            }),
        )
        .test('minimumSubStatuses', function (subStatuses) {
          if (!this.parent.SUB_STATUS.isExist) return true;
          const arr =
            subStatuses?.filter(
              (item) =>
                item.lifecycleStatus !== StatusLifecycleStatuses.Deleted && (item.names.ENGLISH || item.names.RUSSIAN),
            ) || [];
          if (arr.length < 1) {
            return this.createError({
              message: t('errors.requiredField'),
              path: 'subStatusNames',
            });
          }
          return true;
        }),
      group: Yup.string().required(t('errors.requiredField')),
      VACANCY: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      SUB_STATUS: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      REMINDER: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      CALENDAR: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      FILE: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      LINK: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      NOTIFICATION: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      AGREEMENT: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
      FEEDBACK: Yup.object().shape({
        isExist: Yup.boolean(),
        isMandatory: Yup.boolean(),
      }),
    });
  }, [t]);

  return { validationSchema };
};
