import { styled } from '@innowise-group/mui-kit';
import { TextField } from '@mui/material';

export const Input = styled(TextField)`
  &.MuiTextField-root {
    margin-top: 10px;
  }
  .MuiOutlinedInput-root {
    border-radius: 6px;
    transition: 0.2s;

    &.Mui-disabled {
      & svg {
        opacity: 0.38;
      }
      background-color: ${({ theme }) => theme.palette.general.lightGrey[30]};
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: ${({ theme, error }) =>
          error ? theme.palette.state.error.pressed : theme.palette.general.darkGrey[80]};
      }
    }
  }
  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, disabled, error }) =>
        error ? theme.palette.state.error.pressed : !disabled && theme.palette.general.darkGrey[80]};
    }
  }

  input {
    padding: 0.75rem 1.25rem;
    height: 1.25rem;
    padding-left: ${({ InputProps }) => InputProps?.startAdornment && '2px'};
    padding-right: ${({ InputProps }) => InputProps?.endAdornment && '5px'};
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    ${({ theme }) => theme.fonts.primary[50]}
    &::placeholder {
      ${({ theme }) => theme.fonts.primary[50]}
      line-height: 1;
      color: ${({ theme }) => theme.palette.general.lightGrey[80]};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  textarea {
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    ${({ theme }) => theme.fonts.primary[50]}
  }
  .MuiInputLabel-root {
    ${({ theme }) => theme.fonts.primary[40]}
    line-height: 1.25;
    top: ${({ value }) => (value ? '4px' : '-4px')};
    color: ${({ theme, error }) => (error ? theme.palette.state.error.pressed : theme.palette.general.darkGrey[80])};
    &.Mui-focused {
      ${({ theme }) => theme.fonts.primary[50]};
      line-height: 1;
      font-weight: 400;
      top: 4px;
      color: ${({ theme, error }) => (error ? theme.palette.state.error.pressed : theme.palette.general.darkGrey[80])};
    }
    &.Mui-error {
      color: ${({ theme }) => theme.palette.state.error.pressed};
    }
  }
  .MuiFormHelperText-root {
    ${({ theme }) => theme.fonts.primary[10]};
    line-height: 1;
    margin: 5px 0 0 0;
  }
  .MuiFormLabel-asterisk {
    color: ${({ theme }) => theme.palette.state.error.pressed};
  }
`;
