import { styled, css } from '@innowise-group/mui-kit';
import { Icon } from '../icon';
import { Tabs, Tab as BaseTab, NavigationTabLink, TabContainer } from '../tabs';
import {
  APP_HEADER_HEIGHT,
  NAVIGATION_BAR_LAPTOP_WIDTH,
  NAVIGATION_BAR_WIDTH,
  NAVIGATION_ICON_CONTAINER_WIDTH,
} from '@constants';

export const Tab = styled(BaseTab)`
  & a,
  span {
    height: 100%;
  }
`;

interface NavigationBarContainerProps {
  collapsed: number;
}
export const NavigationBarContainer = styled.aside<NavigationBarContainerProps>`
  width: ${(props) => (props.collapsed ? NAVIGATION_ICON_CONTAINER_WIDTH : NAVIGATION_BAR_WIDTH)}rem;
  border-right: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
  background-color: ${({ theme }) => theme.palette.general.paper};
  transition: ${({ theme }) => theme.animation.transition};
  position: sticky;
  top: ${APP_HEADER_HEIGHT}px;
  height: calc(100vh - ${APP_HEADER_HEIGHT}px);
  padding-top: 24px;

  ${({ collapsed }) => {
    return css`
      p {
        transition: ${({ theme }) => theme.animation.transition};
        display: ${collapsed ? 'none' : 'block'};
      }
    `;
  }};

  @media (max-width: ${NAVIGATION_BAR_LAPTOP_WIDTH}px) {
    position: fixed;
    z-index: 1306;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: 4rem;
    padding: 0;
    position: static;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 0px 4px;
  }
`;

export const NavigationBarContentContainer = styled.div`
  width: inherit;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 100%;
  }
`;

interface NavigationItemProps {
  selected: number;
}
export const NavigationItem = styled.div<NavigationItemProps>`
  display: flex;
  align-items: center;
  height: 60px;
  min-width: ${NAVIGATION_ICON_CONTAINER_WIDTH}rem;
  position: relative;
  cursor: pointer;
  transition: ${({ theme }) => theme.animation.transition};

  & * {
    ${({ theme }) => theme.fonts.primary[70]}
    line-height: 22px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  ${({ selected, theme }) => {
    return css`
      background-color: ${selected ? theme.palette.state.error.background : 'transparent'};

      & * {
        transition: color 0.2s;
        color: ${selected ? theme.palette.general.darkGrey[100] : theme.palette.general.darkGrey[60]};
      }

      &:hover {
        & * {
          color: ${theme.palette.general.darkGrey[100]};
        }

        ${IconItem} {
          svg {
            fill: ${theme.palette.general.smart};
          }
        }
      }
    `;
  }};
`;

export const NavigationLinkText = styled.div`
  white-space: nowrap;
  ${({ theme }) => theme.fonts.primary[50]}
  position: relative;
  left: -3px;
  margin: 0;
`;

export const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  z-index: 3;
  right: -12px;
  height: 28px;
  width: 28px;
  border: 1px solid ${({ theme }) => theme.palette.general.darkGrey[20]};
  background-color: ${({ theme }) => theme.palette.general.paper};
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.palette.general.lightGrey[60]};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const IconItem = styled(Icon)<{ isCollapsed?: boolean }>`
  margin: 0;
  transition: 0.2s;
  width: ${NAVIGATION_ICON_CONTAINER_WIDTH}rem;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: min-content;
  }
`;

export const CustomTabs = styled(Tabs)`
  &.MuiTabs-root {
    border-right: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
    border-bottom: none;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      height: 100%;
    }
  }
  & .MuiTabs-indicator {
    left: 0;
  }
  ${TabContainer} {
    padding: 0;
  }
  ${NavigationTabLink} {
    padding-bottom: 0;
    flex: 1;
    height: 100%;
  }
  & .MuiMenuItem-root {
    flex: 1;
  }
`;
