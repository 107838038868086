import { styled, css } from '@innowise-group/mui-kit';
import { AnimationWrapper } from '../shared';

const FontStyles = css`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const Wrapper = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
`;

export const FirstSelectedValue = styled.p``;

export const SelectedCount = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 3px;
  padding: 3px 5px;
  margin-left: 5px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  background: ${({ theme }) => theme.palette.general.paper};
`;

export const Label = styled.h3`
  ${FontStyles}
  font-weight: 600;
`;

export const ControlsContainer = styled.div`
  ${FontStyles}
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;
