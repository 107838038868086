import { styled } from '@innowise-group/mui-kit';
import { AnimationWrapper } from '../shared';

export const Wrapper = styled.div``;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstSelectedValue = styled.p``;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
`;

export const FilterLabelDate = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 3px;
  padding: 3px 5px;
  margin-left: 5px;
`;

export const FilterWrapper = styled.div`
  background: ${({ theme }) => theme.palette.general.paper};
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const SegmentsContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: space-around;
`;

export const Segment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SegmentTitle = styled.h1`
  ${({ theme }) => theme.fonts.primary[20]}
`;
