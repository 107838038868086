import { Button } from '@innowise-group/mui-kit';
import { Icon, styled } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  padding: 1.5rem 1.5rem 0 1.5rem;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
`;

export const VerticalDivider = styled.div`
  height: 40px;
  width: 1px;
  margin: 0 5px;
  background: ${({ theme }) => theme.palette.general.lightGrey[60]};
`;

export const CustomButton = styled(Button)`
  width: auto;
  font-size: 0.75rem;
  height: 33.5px;
`;

export const RecruiterModeIcon = styled(Icon)`
  & > svg {
    fill: transparent;
  }
  height: 0.875rem;
`;

export const ResetButton = styled(Button)`
  padding: 0 10px;
  width: auto;
`;

export const SubmitButton = styled(Button)`
  padding: 0 30px;
  width: auto;
`;

export const RecruiterModeContainer = styled.div`
  width: auto;
  margin-left: auto;
`;
