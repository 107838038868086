import { styled } from '@innowise-group/mui-kit';
import { TextFilter } from '../text';

export const CustomTextFilter = styled(TextFilter)`
  & .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.general.paper};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.general.lightGrey[60]};
  }
`;

export const RemoveFilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;
