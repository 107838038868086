import { getDateStringWithoutTimezone } from '@innowise-group/utilities';
import { compareCandidates, getUpdatesStatus } from './candidates.helpers';
import {
  CandidateChangesStatuses,
  CandidateContacts,
  CandidateCourseElement,
  CandidateDiffElem,
  CandidateDuffMerge,
  CandidateEducationElement,
  CandidateExperience,
  CandidateFileItemResponse,
  CandidateItem,
  CandidateItemResponse,
  CandidateLanguage,
  CandidateProfession,
  CandidatesState,
  FormattedCandidateDiffMerge,
  ShortCandidateItemResponse,
  StatusItem,
  StatusItemResponse,
} from './candidates.types';
import {
  CandidateLifecycleStatuses,
  ClaimTypes,
  ContactsType,
  candidateDefaultEmploymentForms,
  candidateDefaultWorkFormats,
  candidateDefaultWorkloads,
  candidateFormDefaultValues,
} from '@constants';
import { ListItemsResponse, SelectOption } from '@innowise-group/core';

export const mapCandidateItemToCandidateItemResponse = (data: CandidateItem): CandidateItemResponse => ({
  ...data,
  candidateReferral: {
    reward: data.candidateReferral?.reward || '',
    referralComment: data.candidateReferral?.referralComment || '',
    startGrade: data.candidateReferral?.startGrade || '',
    recommender: {
      id: '',
      firstNameEn: '',
      lastNameEn: '',
      firstNameRu: '',
      lastNameRu: '',
      email: data.candidateReferral.recommenderEmail || '',
    },
  },
  citizenships:
    data.citizenshipId?.map((item) => ({
      id: item.value,
      name: item.title,
    })) || [],
  source: {
    valueId: data.sourceId,
    translation: '',
  },
  candidateOverallExperienceMonths: 0,
  candidateItSphereExperienceMonths: 0,
  lastModifiedDate: data?.lastModifiedDate || '',
  lifecycleStatus: CandidateLifecycleStatuses.Actual,
  photoId: data.photoId || 0,
  firstNameEn: data?.firstNameEn || '',
  lastNameEn: data?.lastNameEn || '',
  firstNameRu: data?.firstNameRu || '',
  lastNameRu: data?.lastNameRu || '',
  birthDate: data?.birthDate ? getDateStringWithoutTimezone(data?.birthDate) : null,
  email: data?.email || '',
  candidateContacts: data.candidateContacts?.map(({ contactType, contact, id }) => {
    if (contactType === ContactsType.PHONE || contactType === ContactsType.ADDITIONAL_PHONE) {
      return {
        id: id || '',
        contactType: contactType,
        contact: contact?.value?.length ? `+${contact?.value?.replace('+', '')}` : '',
      };
    }
    return {
      contactType: contactType,
      contact: contact?.value || '',
    };
  }),
  candidateProfessions: data.candidateProfessions?.map((profession) => ({
    id: profession?.id || '',
    profession: {
      id: profession?.professionId || '',
      valueId: profession.professionId,
      translation: '',
    },
    grade: profession.gradeId,
  })),
  candidateLanguages: data.candidateLanguages.reduce(
    (acc, lng) =>
      lng.languageId
        ? [
            ...acc,
            {
              id: lng?.id || '',
              language: lng.languageId,
              languageLevel: lng.languageLevelId,
            },
          ]
        : acc,
    [],
  ),
  candidateTechnologies: data.candidateTechnologies.map((item) => ({
    dictionaryValue: {
      valueId: item.value,
      translation: item.title,
    },
  })),
  candidateVisas: data.candidateVisas?.map((visa) => ({
    id: visa?.id || '',
    visaType: {
      valueId: visa.visaTypeId,
      translation: '',
    },
    validTo: visa.validTo ? getDateStringWithoutTimezone(visa.validTo) : null,
    isFullVisaDate: visa.isFullVisaDate,
  })),
  candidateSalary: {
    ...data.candidateSalary,
    id: 0,
    currency: data.candidateSalary.currencyId,
    expectedSalaryMin: data.candidateSalary.expectedSalaryMin ? Number(data.candidateSalary.expectedSalaryMin) : null,
    expectedSalaryMax: data.candidateSalary.expectedSalaryMax ? Number(data.candidateSalary.expectedSalaryMax) : null,
  },
  candidateLocation: {
    currentLocationCity: {
      id: data.candidateLocation.currentLocationCityId,
      name: '',
      countryId: data.candidateLocation.currentLocationCountryId,
    },
    currentLocationCountry: {
      id: data.candidateLocation.currentLocationCountryId,
      name: '',
    },
    locationCity: {
      id: data.candidateLocation.locationCityId,
      name: '',
      countryId: data.candidateLocation.locationCountryId,
    },
    locationCountry: {
      id: data.candidateLocation.locationCountryId,
      name: '',
    },
  },
  candidateDomains: data.candidateDomains?.map((item) => ({
    dictionaryValue: {
      valueId: item.value,
      translation: item.title,
    },
  })),
  candidateWorkFormats: data.candidateWorkFormats?.reduce?.((acc, { dictionaryValueId: valueId, checked }) => {
    return checked ? [...acc, valueId] : acc;
  }, []),
  candidateWorkloads: data?.candidateWorkloads?.reduce((acc, { dictionaryValueId: valueId, checked }) => {
    return checked ? [...acc, valueId] : acc;
  }, []),
  employmentForms: data?.employmentForms?.reduce((acc, { dictionaryValueId: valueId, checked }) => {
    return checked ? [...acc, valueId] : acc;
  }, []),
  candidateRelocationCountries: data?.candidateRelocationCountries?.map((item) => ({
    country: {
      id: item.value,
      name: item.title,
    },
    id: 0,
  })),
  candidateLinks: data.candidateLinks?.reduce((acc, link) => {
    if (!link.name && !link.url) {
      return acc;
    }
    return [...acc, link];
  }, []),
  responsibleEmployee: {
    id: 0,
    employeeId: Number(data.responsibleEmployee),
    firstNameEn: '',
    firstNameRu: '',
    lastNameEn: '',
    lastNameRu: '',
    lifecycleStatus: '',
    jobTitle: { valueId: '', translation: '' },
    miniPhotoHrmUrl: null,
    role: {
      name: null,
      permissions: [],
    },
  },
  candidateExperiences: data.candidateExperiences.reduce((acc, experience) => {
    if (experience.company || experience.position) {
      return [
        ...acc,
        {
          ...experience,
          comment: experience?.comment || '',
          ...(experience.workedFrom
            ? { workedFrom: getDateStringWithoutTimezone(experience.workedFrom) }
            : { workedFrom: null }),
          ...(experience.workedTo
            ? { workedTo: getDateStringWithoutTimezone(experience.workedTo) }
            : { workedTo: null }),
        },
      ];
    }
    return acc;
  }, []),
  candidateEducations: data.candidateEducations?.reduce((acc, education) => {
    if (education.profession || education.studyPlace) {
      return [
        ...acc,
        {
          ...education,
          comment: education?.comment || '',
          ...(education.studiedFrom
            ? { studiedFrom: getDateStringWithoutTimezone(education.studiedFrom) }
            : { studiedFrom: null }),
          ...(education.studiedTo
            ? { studiedTo: getDateStringWithoutTimezone(education.studiedTo) }
            : { studiedTo: null }),
        },
      ];
    }
    return acc;
  }, []),
  candidateCourses: data.candidateCourses?.reduce((acc, course) => {
    if (course.courseName || course.studyPlace) {
      return [
        ...acc,
        {
          ...course,
          comment: course?.comment || '',
          ...(course.studiedFrom
            ? { studiedFrom: getDateStringWithoutTimezone(course.studiedFrom) }
            : { studiedFrom: null }),
          ...(course.studiedTo ? { studiedTo: getDateStringWithoutTimezone(course.studiedTo) } : { studiedTo: null }),
        },
      ];
    }
    return acc;
  }, []),
});

export const mapCandidateItemResponseToCandidateItem = (candidate: CandidateItemResponse): CandidateItem => {
  if (!candidate) return null;
  const telegramContact = candidate.candidateContacts.find((item) => item.contactType === ContactsType.TELEGRAM);
  const gitHubContact = candidate.candidateContacts.find((item) => item.contactType === ContactsType.GITHUB);
  const linkedInContact = candidate.candidateContacts.find((item) => item.contactType === ContactsType.LINKEDIN);
  const skypeContact = candidate.candidateContacts.find((item) => item.contactType === ContactsType.SKYPE);
  const behanceContact = candidate.candidateContacts.find((item) => item.contactType === ContactsType.BEHANCE);
  const phone = {
    ...candidate.candidateContacts.find((item) => item.contactType === ContactsType.PHONE),
    contact:
      candidate.candidateContacts.find((item) => item.contactType === ContactsType.PHONE)?.contact.replace('+', '') ||
      '',
  };
  const additionalPhone = {
    ...candidate.candidateContacts.find((item) => item.contactType === ContactsType.ADDITIONAL_PHONE),
    contact:
      candidate.candidateContacts
        .find((item) => item.contactType === ContactsType.ADDITIONAL_PHONE)
        ?.contact.replace('+', '') || '',
  };

  const newCandidate: CandidateItem = {
    ...candidate,
    id: candidate.id,
    photoId: candidate.photoId || 0,
    firstNameRu: candidate.firstNameRu,
    firstNameEn: candidate.firstNameEn,
    lastNameRu: candidate.lastNameRu,
    lastNameEn: candidate.lastNameEn,
    filesMeta: candidate.filesMeta,
    visaAvailability: candidate.visaAvailability,
    birthDate: candidate.birthDate ? new Date(candidate.birthDate) : null,
    email: candidate.email,
    citizenshipId:
      candidate.citizenships?.map((item) => {
        return { value: item.id?.toString(), title: item.name };
      }) || [],
    isBlocked: candidate.isBlocked,
    isBlockedReason: candidate.isBlockedReason,
    sourceDetails: candidate.sourceDetails,
    relocationAvailability: !!candidate.relocationAvailability,
    sourceId: candidate.source.valueId,
    vacancy: candidate?.candidatePrioritizedVacancies?.map((item) => {
      return { value: item.id.toString(), title: item.name };
    }),
    candidateReferral: {
      recommenderEmail: candidate.candidateReferral?.recommender?.email || '',
      reward: candidate.candidateReferral?.reward || '',
      referralComment: candidate.candidateReferral?.referralComment || '',
      startGrade: candidate.candidateReferral?.startGrade || '',
    },
    candidateContacts: [
      {
        id: telegramContact?.id,
        contactType: ContactsType.TELEGRAM,
        contact: { value: telegramContact ? telegramContact.contact : '' },
      },
      {
        id: linkedInContact?.id,
        contactType: ContactsType.LINKEDIN,
        contact: { value: linkedInContact ? linkedInContact.contact : '' },
      },
      {
        id: gitHubContact?.id,
        contactType: ContactsType.GITHUB,
        contact: { value: gitHubContact ? gitHubContact.contact : '' },
      },
    ].concat([
      {
        contactType: ContactsType.PHONE,
        contact: { value: phone ? phone.contact : '' },
        ...(phone?.id && { id: phone.id }),
      },
      {
        contactType: ContactsType.ADDITIONAL_PHONE,
        contact: { value: additionalPhone ? additionalPhone.contact : '' },
        ...(additionalPhone?.id && { id: additionalPhone.id }),
      },
      {
        id: behanceContact?.id,
        contactType: ContactsType.BEHANCE,
        contact: { value: behanceContact ? behanceContact.contact : '' },
      },
      {
        id: skypeContact?.id,
        contactType: ContactsType.SKYPE,
        contact: { value: skypeContact ? skypeContact.contact : '' },
      },
    ]),
    candidateExperiences: candidate.candidateExperiences.length
      ? candidate.candidateExperiences.map((item) => {
          return {
            ...item,
            comment: item.comment || '',
            stillWorking: !!item.stillWorking,
            workedFrom: item.workedFrom ? new Date(item.workedFrom) : null,
            workedTo: item.workedTo ? new Date(item.workedTo) : null,
          };
        })
      : candidateFormDefaultValues.candidateExperiences,
    candidateProfessions: candidate.candidateProfessions.length
      ? candidate.candidateProfessions.map((item) => {
          return {
            ...(item.id && { id: item.id }),
            id: item.id,
            professionId: item.profession.valueId,
            gradeId: item.grade || '',
          };
        })
      : candidateFormDefaultValues.candidateProfessions,
    candidateLanguages: candidate.candidateLanguages.length
      ? candidate.candidateLanguages.map((item) => {
          return {
            ...(item.id && { id: item.id }),
            languageId: item.language,
            languageLevelId: item.languageLevel || '',
          };
        })
      : candidateFormDefaultValues.candidateLanguages,
    responsibleEmployee: candidate.responsibleEmployee.employeeId.toString() || '',
    candidateVisas: candidate.candidateVisas?.length
      ? candidate.candidateVisas.map((item) => {
          return {
            ...(item.id && { id: item.id }),
            visaTypeId: item.visaType?.valueId || '',
            validTo: item.validTo ? new Date(item.validTo) : null,
            isFullVisaDate: item.isFullVisaDate,
          };
        })
      : [
          {
            visaTypeId: '',
            validTo: null,
            isFullVisaDate: false,
          },
        ],
    candidateSalary: {
      expectedSalaryComment: candidate.candidateSalary?.expectedSalaryComment || '',
      expectedSalaryMax: candidate.candidateSalary?.expectedSalaryMax || '',
      expectedSalaryMin: candidate.candidateSalary?.expectedSalaryMin || '',
      currencyId: candidate.candidateSalary?.currency || '',
    },
    candidateLocation: {
      locationCountryId: candidate.candidateLocation.locationCountry?.id.toString() || '',
      currentLocationCityId: candidate.candidateLocation.currentLocationCity?.id || '',
      currentLocationCountryId: candidate.candidateLocation.currentLocationCountry?.id.toString() || '',
      locationCityId: candidate.candidateLocation.locationCity?.id || '',
    },
    candidateDomains: candidate.candidateDomains.map((item) => {
      return { value: item.dictionaryValue.valueId, title: item.dictionaryValue.translation };
    }),
    candidateTechnologies: candidate.candidateTechnologies.map((item) => {
      return { value: item.dictionaryValue.valueId, title: item.dictionaryValue.translation };
    }),
    candidateWorkFormats: candidateDefaultWorkFormats.map((item) => {
      return {
        dictionaryValueId: item.dictionaryValueId,
        checked: candidate.candidateWorkFormats.includes(item.dictionaryValueId),
      };
    }),
    employmentForms: candidateDefaultEmploymentForms.map((item) => {
      return {
        dictionaryValueId: item.dictionaryValueId,
        checked: candidate.employmentForms.includes(item.dictionaryValueId),
      };
    }),
    candidateWorkloads: candidateDefaultWorkloads.map((item) => {
      return {
        dictionaryValueId: item.dictionaryValueId,
        checked: candidate.candidateWorkloads.includes(item.dictionaryValueId),
      };
    }),
    candidateRelocationCountries: candidate.candidateRelocationCountries.map((item) => {
      return { value: item.country.id?.toString(), title: item.country.name };
    }),
    candidateSkills: candidate.candidateSkills || '',
    candidateLinks: candidate.candidateLinks.length
      ? candidate.candidateLinks.map((item) => ({
          ...(item.id && { id: item.id }),
          url: item.url || '',
          name: item.name || '',
        }))
      : candidateFormDefaultValues.candidateLinks,
    candidateEducations: candidate.candidateEducations.length
      ? candidate.candidateEducations.map((item) => {
          return {
            ...item,
            comment: item.comment || '',
            studiedFrom: item.studiedFrom ? new Date(item.studiedFrom) : null,
            studiedTo: item.studiedTo ? new Date(item.studiedTo) : null,
          };
        })
      : candidateFormDefaultValues.candidateEducations,
    candidateCourses: candidate.candidateCourses.length
      ? candidate.candidateCourses.map((item) => {
          return {
            ...item,
            comment: item.comment || '',
            studiedFrom: item.studiedFrom ? new Date(item.studiedFrom) : null,
            studiedTo: item.studiedTo ? new Date(item.studiedTo) : null,
          };
        })
      : candidateFormDefaultValues.candidateCourses,
  };
  return newCandidate;
};

export const mapCandidateDiffMergeToFormattedCandidateDiff = (
  diff: CandidateDuffMerge,
): FormattedCandidateDiffMerge => {
  return {
    id: diff.id.target,
    photoId: diff.photoId.target,
    photo: { ...diff.photo },
    birthDate: {
      ...diff.birthDate,
      preselected: diff?.birthDate?.preselected
        ? getDateStringWithoutTimezone(new Date(diff?.birthDate?.preselected))
        : null,
      target: diff?.birthDate?.target ? getDateStringWithoutTimezone(new Date(diff?.birthDate?.target)) : null,
      updates: diff?.birthDate?.updates ? getDateStringWithoutTimezone(new Date(diff?.birthDate?.updates)) : null,
    },
    filesMeta: candidatesFilesMapper({
      ...diff.filesMeta,
    }),
    candidateLinks: {
      ...diff.candidateLinks,
      preselected: [
        ...diff.candidateLinks.target,
        ...diff.candidateLinks.updates.reduce((acc, { id, ...rest }) => {
          const isExistsInTartget = diff.candidateLinks.target.some(
            ({ name, url }) => name === rest.name && url === rest.url,
          );
          return rest.name && !isExistsInTartget
            ? [
                ...acc,
                {
                  ...rest,
                  ...(id && { id }),
                },
              ]
            : acc;
        }, []),
      ],
    },

    firstNameRu: diff.firstNameRu,
    lastNameRu: diff.lastNameRu,
    firstNameEn: diff.firstNameEn,
    lastNameEn: diff.lastNameEn,
    relocationAvailability: diff.relocationAvailability,
    visaAvailability: diff.visaAvailability,
    // TODO: update after package consolidation
    readyForBusinessTrip: diff.readyForBusinessTrip,
    sourceDetails: diff.sourceDetails,
    isBlocked: diff.isBlocked,
    isBlockedReason: diff.isBlockedReason,
    email: diff.email,
    candidateSkills: diff.candidateSkills,
    aboutMe: diff?.aboutMe,

    // TODO: REMOVE!!!
    gender: {
      ...diff?.gender,
      status:
        !diff?.gender?.target && !diff?.gender?.updates ? CandidateChangesStatuses.Unchanged : diff?.gender?.status,
    },

    responsibleEmployee: diff.responsibleEmployee.employeeId,
    source: diff.source.valueId,

    candidateWorkloads: simpleStringArrayDiffMapper(diff.candidateWorkloads),
    candidateWorkFormats: simpleStringArrayDiffMapper(diff.candidateWorkFormats),
    employmentForms: simpleStringArrayDiffMapper(diff.employmentForms),

    candidateDomains: candidateDictionariesMapper(diff.candidateDomains),
    candidateTechnologies: candidateDictionariesMapper(diff.candidateTechnologies),
    candidatePrioritizedVacancies: candidateVacanciesMapper(diff.candidatePrioritizedVacancies),

    candidateRelocationCountries: locationMapper(diff.candidateRelocationCountries),
    candidateLocation: candidateLocationsMapper(diff.candidateLocation),

    candidateLanguages: candidateLanguagesMapper(diff.candidateLanguages),
    candidateProfessions: candidateProfessionsMapper(diff.candidateProfessions),
    candidateVisas: candidateVisasMapper(diff.candidateVisas),
    // TODO: update after package consolidation
    citizenshipId: {
      status: CandidateChangesStatuses.Unchanged,
      target: [],
      updates: [],
      preselected: [],
    },

    candidateExperiences: candidateMapDataMapper<CandidateExperience>(diff.candidateExperiences, [
      'company',
      'position',
      'workedFrom',
      'workedTo',
    ]),
    candidateCourses: candidateMapDataMapper<CandidateCourseElement>(diff.candidateCourses, [
      'courseName',
      'studyPlace',
      'studiedFrom',
      'studiedTo',
    ]),
    candidateEducations: candidateMapDataMapper<CandidateEducationElement>(diff.candidateEducations, [
      'profession',
      'studyPlace',
      'studiedFrom',
      'studiedTo',
    ]),

    candidateSalary: candidateSalaryMapper(diff.candidateSalary),

    candidateContacts: candidateContactsMapper(diff),
  };
};

export const candidateContactsMapper = (
  diff: Pick<CandidateDuffMerge, 'candidateContacts' | 'email'>,
): FormattedCandidateDiffMerge['candidateContacts'] => {
  const { candidateContacts, email } = diff;
  const initial = {
    target: '',
    updates: '',
    preselected: '',
    status: CandidateChangesStatuses.Unchanged,
  };
  const contactsMap: { [key in ContactsType]: CandidateDiffElem<string> } = {
    [ContactsType.ADDITIONAL_PHONE]: initial,
    [ContactsType.BEHANCE]: initial,
    [ContactsType.GITHUB]: initial,
    [ContactsType.LINKEDIN]: initial,
    [ContactsType.PHONE]: initial,
    [ContactsType.SKYPE]: initial,
    [ContactsType.TELEGRAM]: initial,
  };
  candidateContacts?.target?.forEach((el) => {
    contactsMap[el.contactType] = {
      ...contactsMap[el.contactType],
      ...(el?.id && { id: el.id }),
      target: el.contact,
      preselected: el.contact,
    };
  });
  candidateContacts.updates?.forEach((el) => {
    contactsMap[el.contactType] = {
      ...contactsMap[el.contactType],
      preselected: contactsMap[el.contactType]?.preselected || '',
      updates: el.contact,
    };
  });

  const contactsWithStatuses = Object.entries<Pick<CandidateDiffElem<string>, 'target' | 'updates'>>(
    contactsMap,
  ).reduce(
    (acc, [key, val]) => {
      return {
        ...acc,
        [key]: {
          ...val,
          status: getUpdatesStatus(val.target, val.updates),
        },
      };
    },
    { [CandidateContacts.AdditionalPhone]: { preselected: '' } } as {
      [key in CandidateContacts]: CandidateDiffElem<string>;
    },
  );
  return {
    [CandidateContacts.Email]: email,
    ...contactsWithStatuses,
  };
};

const compareDictionaryValuesMapper = ({ updates, target }: { updates: SelectOption[]; target: SelectOption[] }) => {
  const allFormattedDictionariesValues = [...updates, ...target];
  return allFormattedDictionariesValues.reduce(
    (acc, val) => {
      const isTargetIncludes = target.some((el) => el.value === val.value);
      const isUpdatesIncludes = updates.some((el) => el.value === val.value);
      if (isTargetIncludes && isUpdatesIncludes) {
        const isExists = acc?.preselected?.some((el) => el.value === val.value);
        if (isExists) return acc;
        return {
          ...acc,
          preselected: acc?.preselected ? [...acc.preselected, val] : [val],
        };
      }
      if (isTargetIncludes && !isUpdatesIncludes) {
        return {
          ...acc,
          target: acc?.target ? [...acc.target, val] : [val],
        };
      }
      if (!isTargetIncludes && isUpdatesIncludes) {
        return {
          ...acc,
          updates: acc?.updates ? [...acc.updates, val] : [val],
        };
      }
      return acc;
    },
    { preselected: [], target: [], updates: [] } as FormattedCandidateDiffMerge[
      | 'candidateTechnologies'
      | 'candidateRelocationCountries'
      | 'candidateDomains'],
  );
};

export const simpleStringArrayDiffMapper = (args: { target: string[]; updates: string[] }) => {
  const { target: initialTarget, updates: initialUpdates } = args;
  const preselected = [...new Set([...initialTarget, ...initialUpdates])].reduce((acc, val) => {
    if (initialTarget.includes(val) && initialUpdates.includes(val)) {
      return [...acc, val];
    }
    return acc;
  }, []);

  const target = initialTarget.reduce((acc, val) => {
    if (initialUpdates.includes(val)) return acc;
    return [...acc, val];
  }, []);

  const updates = initialUpdates.reduce((acc, val) => {
    if (initialTarget.includes(val)) return acc;
    return [...acc, val];
  }, []);

  const status =
    updates?.length || target?.length ? CandidateChangesStatuses.Updated : CandidateChangesStatuses.Unchanged;

  return {
    preselected,
    target,
    updates,
    status,
  };
};

export const candidatesFilesMapper = (args: {
  target: CandidateFileItemResponse[];
  updates: CandidateFileItemResponse[];
}) => {
  const { updates, target } = args;
  const allFilesValues = [...updates, ...target];

  return allFilesValues.reduce((acc, val) => {
    if (acc.some(({ id }) => id === val.id)) {
      return acc;
    }
    return [...acc, val];
  }, []);
};

export const candidateDictionariesMapper = (arg: CandidateDuffMerge['candidateTechnologies' | 'candidateDomains']) => {
  const formattedDictionariesValues = Object.entries(arg).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: val.map(({ dictionaryValue, id }) => ({
        title: dictionaryValue.translation,
        value: dictionaryValue.valueId,
        id,
      })),
    };
  }, {} as Pick<CandidateDiffElem<SelectOption[]>, 'target' | 'updates'>);

  return compareDictionaryValuesMapper(formattedDictionariesValues);
};

export const locationMapper = (arg: CandidateDuffMerge['candidateRelocationCountries']) => {
  const formattedDictionariesValues = Object.entries(arg).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: val.map(({ country, id }) => ({
        title: country.name,
        value: country.id,
        id,
      })),
    };
  }, {} as Pick<CandidateDiffElem<SelectOption[]>, 'target' | 'updates'>);

  return compareDictionaryValuesMapper(formattedDictionariesValues);
};

export const candidateVacanciesMapper = (arg: CandidateDuffMerge['candidatePrioritizedVacancies']) => {
  const formattedDictionariesValues = {
    ...arg,
    target: arg.target?.map(({ id, name }) => ({ value: id.toString(), title: name })),
    updates: arg.updates?.map(({ id, name }) => ({ value: id.toString(), title: name })),
  };

  const { updates, target } = formattedDictionariesValues;

  const allFormattedDictionariesValues = [...updates, ...target];
  return allFormattedDictionariesValues.reduce(
    (acc, val) => {
      const isTargetIncludes = target.some(({ value }) => value === val.value);
      const isUpdatesIncludes = updates.some(({ value }) => value === val.value);
      if (isTargetIncludes && isUpdatesIncludes) {
        const isExists = acc?.preselected?.some(({ value }) => value === val.value);
        if (isExists) return acc;
        return {
          ...acc,
          preselected: acc?.preselected ? [...acc.preselected, val] : [val],
        };
      }
      if (isTargetIncludes && !isUpdatesIncludes) {
        return {
          ...acc,
          target: acc?.target ? [...acc.target, val] : [val],
        };
      }
      if (!isTargetIncludes && isUpdatesIncludes) {
        return {
          ...acc,
          updates: acc?.updates ? [...acc.updates, val] : [val],
        };
      }
      return acc;
    },
    { preselected: [], target: [], updates: [] } as FormattedCandidateDiffMerge['candidatePrioritizedVacancies'],
  );
};

export const candidateLanguagesMapper = (entities: CandidateDuffMerge['candidateLanguages']) => {
  const formattedLanguages = Object.entries(entities).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: val?.map((el) => {
        const languageId = el.language;
        const languageLevelId = el.languageLevel || '';
        return {
          ...(el.id && { id: el.id }),
          languageId,
          languageLevelId,
        };
      }),
    };
  }, {} as Pick<CandidateDiffElem<CandidateLanguage[]>, 'target' | 'updates'>);

  const { updates, target } = formattedLanguages;
  const allLanguages = [...updates, ...target];

  return allLanguages.reduce(
    (acc, { languageId, languageLevelId, id }) => {
      const isTargetIncludes = target.some(
        ({ languageId: targetLanguageId, languageLevelId: targetLanguageLevelId }) =>
          targetLanguageId === languageId && targetLanguageLevelId === languageLevelId,
      );
      const isUpdatesIncludes = updates.some(
        ({ languageId: updatesLanguageId, languageLevelId: updatesLanguageLevelId }) =>
          updatesLanguageId === languageId && updatesLanguageLevelId === languageLevelId,
      );
      const currentLanguage = { languageId, languageLevelId, ...(id && { id }) };
      if (isTargetIncludes && isUpdatesIncludes) {
        const currentLanguageWithStatus = { ...currentLanguage, status: CandidateChangesStatuses.Unchanged };
        const foundedIdx = acc?.preselected?.findIndex(
          ({ languageId: preselectedLanguageId }) => languageId === preselectedLanguageId,
        );
        const isExists = foundedIdx !== -1;
        if (isExists) {
          return {
            ...acc,
            preselected: acc?.preselected.map((el, idx) =>
              foundedIdx === idx
                ? {
                    ...el,
                    ...currentLanguageWithStatus,
                  }
                : el,
            ),
          };
        }
        return {
          ...acc,
          preselected: acc?.preselected ? [...acc.preselected, currentLanguageWithStatus] : [currentLanguageWithStatus],
        };
      }
      if (!isTargetIncludes && isUpdatesIncludes) {
        const currentLanguageWithStatus = { ...currentLanguage, status: CandidateChangesStatuses.Updated };
        return {
          ...acc,
          updates: acc?.updates ? [...acc.updates, currentLanguageWithStatus] : [currentLanguageWithStatus],
        };
      }
      if (isTargetIncludes && !isUpdatesIncludes) {
        const currentLanguageWithStatus = { ...currentLanguage, status: CandidateChangesStatuses.Deleted };
        return {
          ...acc,
          target: acc?.target ? [...acc.target, currentLanguageWithStatus] : [currentLanguageWithStatus],
        };
      }
      return acc;
    },
    { preselected: [], target: [], updates: [] } as FormattedCandidateDiffMerge['candidateLanguages'],
  );
  return;
};

export const candidateProfessionsMapper = (professions: CandidateDuffMerge['candidateProfessions']) => {
  const formattedProfessions = Object.entries(professions).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: val?.map((el) => ({
        ...(el.id && { id: el.id }),
        professionId: el.profession?.valueId,
        gradeId: el.grade || '',
      })),
    }),
    {} as Pick<CandidateDiffElem<CandidateProfession[]>, 'target' | 'updates'>,
  );

  const { updates, target } = formattedProfessions;
  const allProfessions = [...updates, ...target];

  return allProfessions.reduce(
    (acc, { professionId, gradeId, id }) => {
      const isTargetIncludes = target.some(
        ({ professionId: targetProfessionId, gradeId: targetGradeId }) =>
          targetProfessionId === professionId && targetGradeId === gradeId,
      );
      const isUpdatesIncludes = updates.some(
        ({ professionId: updatesProfessionId, gradeId: updatesGradeId }) =>
          updatesProfessionId === professionId && updatesGradeId === gradeId,
      );
      const currentProfession = { professionId, gradeId, ...(id && { id }) };
      if (isTargetIncludes && isUpdatesIncludes) {
        const currentProfessionWithStatus = { ...currentProfession, status: CandidateChangesStatuses.Unchanged };
        const foundedIdx = acc?.preselected?.findIndex(
          ({ professionId: preselectedProfessionId }) => professionId === preselectedProfessionId,
        );
        const isExists = foundedIdx !== -1;
        if (isExists) {
          return {
            ...acc,
            preselected: acc?.preselected.map((el, idx) =>
              foundedIdx === idx
                ? {
                    ...el,
                    ...currentProfessionWithStatus,
                  }
                : el,
            ),
          };
        }
        return {
          ...acc,
          preselected: acc?.preselected
            ? [...acc.preselected, currentProfessionWithStatus]
            : [currentProfessionWithStatus],
        };
      }
      if (!isTargetIncludes && isUpdatesIncludes) {
        const currentProfessionWithStatus = { ...currentProfession, status: CandidateChangesStatuses.Updated };
        return {
          ...acc,
          updates: acc?.updates ? [...acc.updates, currentProfessionWithStatus] : [currentProfessionWithStatus],
        };
      }
      if (isTargetIncludes && !isUpdatesIncludes) {
        const currentProfessionWithStatus = { ...currentProfession, status: CandidateChangesStatuses.Deleted };
        return {
          ...acc,
          target: acc?.target ? [...acc.target, currentProfessionWithStatus] : [currentProfessionWithStatus],
        };
      }
      return acc;
    },
    { preselected: [], target: [], updates: [] } as FormattedCandidateDiffMerge['candidateProfessions'],
  );
};

const locationDiff = (
  source: CandidateDuffMerge['candidateLocation'][keyof CandidateDuffMerge['candidateLocation']],
) => {
  const isDeleted = source?.status === CandidateChangesStatuses.Deleted;
  const isCreated = source?.status === CandidateChangesStatuses.Created;
  const status =
    source.status && source.id
      ? source.status
      : getUpdatesStatus(source?.id?.target?.toString(), source?.id?.updates?.toString());
  if (isDeleted) {
    return {
      target: source?.id?.toString(),
      updates: '',
      preselected: source?.id?.toString(),
      ...(status && { status }),
    };
  }
  if (isCreated) {
    return {
      target: '',
      updates: source?.id?.toString(),
      preselected: '',
      ...(status && { status }),
    };
  }
  return {
    target: source?.id?.target?.toString(),
    updates: source?.id?.updates?.toString(),
    preselected: source?.id?.preselected?.toString(),
    ...(status && { status }),
  };
};

export const candidateLocationsMapper = ({
  currentLocationCountry,
  locationCountry,
  currentLocationCity,
  locationCity,
}: CandidateDuffMerge['candidateLocation']) => ({
  locationCity: locationDiff(locationCity),
  locationCountry: locationDiff(locationCountry),
  currentLocationCity: locationDiff(currentLocationCity),
  currentLocationCountry: locationDiff(currentLocationCountry),
});

export const candidateVisasMapper = (arg: CandidateDuffMerge['candidateVisas']) => {
  return Object.entries(arg).reduce((acc, [key, [val]]) => {
    const {
      target: [targetEl],
      updates: [updatesEl],
    } = arg;

    const visaTypeStatus = getUpdatesStatus(targetEl?.visaType?.valueId, updatesEl?.visaType?.valueId);
    const validToStatus = getUpdatesStatus(
      targetEl?.validTo ? new Date(targetEl?.validTo)?.toDateString() : '',
      updatesEl?.validTo ? new Date(updatesEl?.validTo)?.toDateString() : '',
    );

    let status: CandidateChangesStatuses;
    if (visaTypeStatus === CandidateChangesStatuses.Unchanged && validToStatus === CandidateChangesStatuses.Unchanged) {
      status = CandidateChangesStatuses.Unchanged;
    } else if (visaTypeStatus !== CandidateChangesStatuses.Unchanged) {
      status = visaTypeStatus;
    } else if (validToStatus !== CandidateChangesStatuses.Unchanged) {
      status = CandidateChangesStatuses.Updated;
    } else {
      status = CandidateChangesStatuses.Unchanged;
    }

    const updatesElem = {
      id: val?.id || '',
      visaType: val?.visaType?.valueId || '',
      validTo: val?.validTo ? new Date(val?.validTo)?.toDateString() : '',
      // TODO: update after package consolidation
      isFullVisaDate: false,
    };
    if (key === 'target') {
      return {
        ...acc,
        [key]: { ...updatesElem },
        preselected: { ...updatesElem },
        status,
      };
    }
    return {
      ...acc,
      [key]: { ...updatesElem },
    };
  }, {} as FormattedCandidateDiffMerge['candidateVisas']);
};

export const candidateSalaryMapper = (salary: CandidateDuffMerge['candidateSalary']) => ({
  ...salary,
  currency: { ...salary.currency },
});

export const candidateMapDataMapper = <T extends object>(
  { target, updates }: CandidateDiffElem<T[]>,
  matchFields: (keyof T)[],
) => {
  const allEntities = [...target, ...updates];
  return allEntities.reduce(
    (acc, val) => {
      const entitYInTarget = target.find((el) => {
        return matchFields.reduce((acc, field) => {
          if (el[field] === val[field]) {
            return acc;
          }
          return false;
        }, true);
      });
      const entitYInUpdates = updates.find((el) => {
        return matchFields.reduce((acc, field) => {
          if (el[field] === val[field]) {
            return acc;
          }
          return false;
        }, true);
      });
      if (entitYInTarget && entitYInUpdates) {
        const isExists = acc.preselected.find((el) => {
          return matchFields.reduce((acc, field) => {
            if (!el[field]?.preselected && !val[field]) {
              return acc;
            }
            if (el[field]?.preselected === val[field]) {
              return acc;
            }
            return false;
          }, true);
        });
        if (isExists) return acc;
        return {
          ...acc,
          preselected: [...acc.preselected, compareCandidates({ target: entitYInTarget, updates: entitYInUpdates })],
        };
      }
      if (entitYInTarget && !entitYInUpdates) {
        return {
          ...acc,
          target: [...acc.target, entitYInTarget],
        };
      }
      if (!entitYInTarget && entitYInUpdates) {
        return {
          ...acc,
          updates: [...acc.updates, entitYInUpdates],
        };
      }
    },
    { preselected: [], target: [], updates: [] },
  );
};

export const mapStatusItemResponseToStatusItem = (status: StatusItemResponse) => {
  const claims = status.claims?.filter((item) => item.claimType !== ClaimTypes.AGREEMENT) || [];
  const agreements = status.claims?.filter((item) => item.claimType === ClaimTypes.AGREEMENT) || [];

  const statusItem: StatusItem = {
    vacancyId: status.vacancy?.id,
    vacancyName: status.vacancy?.name,
    vacancyRequestId: status.vacancyClaim?.id.toString(),
    statusId: status.status?.id,
    subStatusId: status.subStatus?.id,
    eventId: status.id,
    file: status.file,
    link: status.link,
    eventDate: status.eventDate,
    hasReminder: status.hasReminder,
    periodFrom: status.periodFrom,
    periodTo: status.periodTo,
    reminderDate: status.reminderDate,
    hrmChecked: true,
    offerChecked: true,
    hrmProfile: status.hrmProfile?.email || '',
    claims: [ClaimTypes.FEEDBACK, ClaimTypes.NOTIFICATION].map((item) => {
      const claim = claims.find((curr) => curr.claimType === item);
      if (claim) {
        return {
          id: claim.id,
          employeeId: claim.employee.employeeId,
          employeeFullName: `${claim.employee.firstNameEn} ${claim.employee.lastNameEn}`,
          claimType: claim.claimType,
          deadline: claim.deadline,
          isActive: claim.active,
          status: claim.status,
        };
      }
      return {
        employeeId: '',
        employeeFullName: '',
        claimType: item,
        deadline: null,
        isActive: false,
      };
    }),
    agreements: agreements.length
      ? agreements.map((item) => {
          return {
            id: item.id,
            employeeId: item.employee.employeeId,
            employeeFullName: `${item.employee.firstNameEn} ${item.employee.lastNameEn}`,
            claimType: ClaimTypes.AGREEMENT,
            deadline: item.deadline,
            isActive: item.active,
            status: item.status,
          };
        })
      : [{ employeeId: '', employeeFullName: '', claimType: ClaimTypes.AGREEMENT, deadline: null, isActive: false }],
    comment: {
      id: status.comment?.id,
      comment: status.comment?.comment || '',
      isProtected: status.comment?.isProtected || false,
    },
  };
  return statusItem;
};

export const candidateNestedFieldsAutoMergeMapper = (args: {
  state: CandidatesState;
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateProfessions' | 'candidateLanguages'>;
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
}) => {
  const { state, source, entity } = args;
  return [
    ...state.candidatesConsolidation.candidatesDiffs[entity].preselected,
    ...state.candidatesConsolidation.candidatesDiffs[entity][source].map((el) => ({
      ...el,
      status: CandidateChangesStatuses.Resolved,
    })),
  ];
};

export const candidateActivityPlaceMapper = (args: {
  state: CandidatesState;
  entity: keyof Pick<FormattedCandidateDiffMerge, 'candidateExperiences' | 'candidateCourses' | 'candidateEducations'>;
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
}) => {
  const { state, source, entity } = args;
  return [
    ...state.candidatesConsolidation.candidatesDiffs[entity].preselected.map((el) => ({
      ...el,
      comment: {
        preselected: el.comment[source],
        target: el.comment[source],
        updates: el.comment[source],
        status: CandidateChangesStatuses.Resolved,
      },
    })),
    ...state.candidatesConsolidation.candidatesDiffs[entity][source].map((el) =>
      Object.entries(el).reduce(
        (acc, [key, val]) => ({
          ...acc,
          [key]: {
            preselected: val,
            target: val,
            updates: val,
            status: CandidateChangesStatuses.Resolved,
          },
        }),
        {},
      ),
    ),
  ];
};

export const candidateSelectDataFieldsMapper = (args: {
  state: CandidatesState;
  entity:
    | 'vacancy'
    | keyof Pick<
        FormattedCandidateDiffMerge,
        | 'candidateTechnologies'
        | 'candidateDomains'
        | 'candidateRelocationCountries'
        | 'candidateWorkFormats'
        | 'candidateWorkloads'
        | 'employmentForms'
        | 'candidatePrioritizedVacancies'
      >;
  source: keyof Pick<CandidateDiffElem, 'target' | 'updates'>;
}) => {
  const { state, source, entity } = args;
  return [
    ...state.candidatesConsolidation.candidatesDiffs[entity].preselected,
    ...state.candidatesConsolidation.candidatesDiffs[entity][source],
  ];
};

export const prioritizedCandidatesMapper = (
  args: ListItemsResponse<ShortCandidateItemResponse>,
): ListItemsResponse<ShortCandidateItemResponse> => ({
  ...args,
  content: args.content.map(({ priority, ...rest }) => ({
    ...rest,
    priority: {
      ...priority,
      profession: Number(priority.profession.toFixed(1)),
      language: Number(priority.language.toFixed(1)),
      technology: Number(priority.technology.toFixed(1)),
      domain: Number(priority.domain.toFixed(1)),
      location: Number(priority.location.toFixed(1)),
      total: Number(priority.total.toFixed(1)),
    },
  })),
});
