import { Popover, PopoverProps, TextFieldProps } from '@mui/material';
import React, { RefCallback, useMemo } from 'react';
import DatePickerLib, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { DateFormats } from '@constants';
import { SelectOption } from '@innowise-group/core';
import { DateWithoutDaysHeader } from './components/date-without-days-header';
import { FullDateHeader } from './components/full-date-header';
import { DateWithTimeHeader } from './components/date-with-time-header';
import * as Styled from './date-picker.styles';
import { format } from 'date-fns';
import { getLanguageFromLS } from '@innowise-group/utilities';

registerLocale('ru', ru);
registerLocale('en-US', en);

export interface DatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> extends Omit<ReactDatePickerProps<CustomModifierNames, WithRange>, 'onChange'>,
    Pick<TextFieldProps, 'helperText' | 'label' | 'placeholder' | 'disabled' | 'error' | 'InputProps'> {
  onChange?: (value: Date) => void;
  dateFormat?: string;
  withoutDays?: boolean;
  withTime?: boolean;
  minYear?: number;
  maxYear?: number;
  isConstrained?: boolean;
  popoverProps?: Pick<PopoverProps, 'transformOrigin' | 'anchorOrigin'>;
  inputRef?: RefCallback<any>;
  renderCustomInput?: (handleClick: (event: React.MouseEvent<HTMLInputElement>) => void) => React.ReactNode;
}

interface DatePickerContainerProps {
  withoutDays?: boolean;
  withTime?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  popoverProps?: PopoverProps;
}

const DatePickerContainer: React.FC<DatePickerContainerProps> = ({
  withoutDays,
  withTime,
  children,
  disabled,
  error,
  ...props
}) => {
  return withTime ? (
    <Styled.ContainerTime {...props} disabled={disabled} error={error}>
      {children}
    </Styled.ContainerTime>
  ) : withoutDays ? (
    <Styled.ContainerWithoutDays {...props} disabled={disabled} error={error}>
      {children}
    </Styled.ContainerWithoutDays>
  ) : (
    <Styled.ContainerFullDate {...props} disabled={disabled} error={error}>
      {children}
    </Styled.ContainerFullDate>
  );
};

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const locale = getLanguageFromLS();

  const {
    label,
    placeholder,
    disabled,
    error,
    onChange,
    withTime,
    withoutDays,
    dateFormat,
    minYear,
    maxYear,
    helperText,
    value,
    selected,
    isConstrained = true,
    InputProps,
    popoverProps,
    required,
    inputRef,
    renderCustomInput,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { yearsOptions, startYear, endYear } = useMemo(() => {
    const yearsOptions: SelectOption[] = [];
    const startYear = minYear || new Date().getFullYear() - 100;
    const endYear = maxYear || new Date().getFullYear() + 50;
    for (let i = startYear; i <= endYear; i++) {
      yearsOptions.push({ value: i.toString(), title: i.toString() });
    }
    return { yearsOptions, startYear, endYear };
  }, [minYear, maxYear]);

  const handleOnSelect = (date: Date, event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    if (!props.selectsRange) handleClose();
  };

  const handleOnChange = (date: Date) => {
    if (withTime && !withoutDays) {
      const newDate = value ? new Date(value) : new Date();
      newDate.setHours(date.getHours());
      newDate.setMinutes(date.getMinutes());
      newDate.setSeconds(date.getSeconds());
      onChange(newDate);
      return handleClose();
    }
    onChange(date);
    if (!props.selectsRange) handleClose();
  };

  const handleReset = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onChange(null);
  };

  return (
    <React.Fragment>
      {renderCustomInput ? (
        renderCustomInput(handleClick)
      ) : (
        <Styled.DatePickerInput
          value={
            selected
              ? format(
                  new Date(selected),
                  dateFormat ||
                    (withTime && withoutDays
                      ? DateFormats.OnlyTime
                      : withoutDays
                      ? DateFormats.MonthAndYear
                      : DateFormats.DayFirst),
                )
              : ''
          }
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          required={required}
          inputRef={inputRef}
          onClick={handleClick}
          helperText={helperText}
          InputProps={
            InputProps || {
              endAdornment: (
                <React.Fragment>
                  {(selected || value) && (
                    <Styled.ClearIcon
                      type="u_multiply"
                      size={18}
                      disabled={disabled}
                      onClick={!props.disabled ? handleReset : null}
                    />
                  )}
                  <Styled.CalendarIcon type={withTime && withoutDays ? 'u_time' : 'u_calendar'} size={20} />
                </React.Fragment>
              ),
            }
          }
        />
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disablePortal
        {...popoverProps}
        {...(isConstrained && { marginThreshold: null })}
      >
        <DatePickerContainer disabled={disabled} error={error} withoutDays={withoutDays} withTime={withTime}>
          <DatePickerLib
            {...props}
            inline
            selected={withTime && !withoutDays ? (value ? new Date(value) : null) : selected}
            value={value}
            openToDate={selected || new Date()}
            onChange={handleOnChange}
            onSelect={handleOnSelect}
            showMonthYearPicker={withoutDays && !withTime}
            showFourColumnMonthYearPicker={withoutDays && !withTime}
            showTimeSelect={withTime}
            showTimeSelectOnly={withTime && withoutDays}
            timeIntervals={15}
            timeCaption=""
            locale={withTime ? 'ru' : locale}
            dateFormat={
              dateFormat || (withTime && withoutDays)
                ? DateFormats.OnlyTime
                : withoutDays
                ? DateFormats.MonthAndYearSlash
                : DateFormats.DaySlashFirst
            }
            renderCustomHeader={({ date, changeMonth, changeYear }) => {
              const decreaseYear = () => {
                if (date.getFullYear() > startYear) {
                  if (props.minDate) {
                    const newDate = new Date(date);
                    newDate.setFullYear(date.getFullYear() - 1);
                    changeYear(
                      newDate.getTime() > props.minDate.getTime()
                        ? date.getFullYear() - 1
                        : props.minDate.getFullYear(),
                    );
                    onChange(newDate.getTime() > props.minDate.getTime() ? newDate : props.minDate);
                  } else {
                    changeYear(date.getFullYear() - 1);
                    const newDate = new Date(new Date(date).setFullYear(date.getFullYear() - 1));
                    if (withoutDays) {
                      newDate.setDate(1);
                      newDate.setHours(0);
                      newDate.setMinutes(0);
                      newDate.setSeconds(0);
                    }
                    onChange(newDate);
                  }
                }
              };
              const increaseYear = () => {
                if (date.getFullYear() < endYear) {
                  if (props.maxDate) {
                    const newDate = new Date(date);
                    newDate.setFullYear(date.getFullYear() + 1);
                    changeYear(
                      newDate.getTime() < props.maxDate.getTime()
                        ? date.getFullYear() + 1
                        : props.maxDate.getFullYear(),
                    );
                    onChange(newDate.getTime() < props.maxDate.getTime() ? newDate : props.maxDate);
                  } else {
                    changeYear(date.getFullYear() + 1);
                    const newDate = new Date(new Date(date).setFullYear(date.getFullYear() + 1));
                    if (withoutDays) {
                      newDate.setDate(1);
                      newDate.setHours(0);
                      newDate.setMinutes(0);
                      newDate.setSeconds(0);
                    }
                    onChange(newDate);
                  }
                }
              };
              const decreaseMonth = () => {
                const newDate = new Date(date);
                const prevMonthDay = new Date(date);
                prevMonthDay.setMonth(prevMonthDay.getMonth() - 1);
                if (new Date(date).getMonth() === prevMonthDay.getMonth()) {
                  newDate.setDate(1);
                  newDate.setDate(newDate.getDate() - 1);
                } else {
                  newDate.setMonth(date.getMonth() - 1);
                }
                if (props.minDate) {
                  changeMonth(
                    newDate.getTime() > props.minDate.getTime() ? newDate.getMonth() : props.minDate.getMonth(),
                  );
                  onChange(newDate.getTime() > props.minDate.getTime() ? newDate : props.minDate);
                } else {
                  changeMonth(newDate.getMonth());
                  if (withoutDays) {
                    newDate.setDate(1);
                    newDate.setHours(0);
                    newDate.setMinutes(0);
                    newDate.setSeconds(0);
                  }
                  onChange(newDate);
                }
              };
              const increaseMonth = () => {
                const nextDay = new Date(date);
                nextDay.setDate(nextDay.getDate() + 1);
                const newDate = new Date(date);
                if (new Date(date).getMonth() + 1 === nextDay.getMonth()) {
                  newDate.setDate(1);
                  newDate.setMonth(date.getMonth() + 2);
                  newDate.setDate(newDate.getDate() - 1);
                } else {
                  newDate.setMonth(date.getMonth() + 1);
                }
                if (props.maxDate) {
                  changeMonth(
                    newDate.getTime() < props.maxDate.getTime() ? newDate.getMonth() + 1 : props.maxDate.getMonth(),
                  );
                  onChange(newDate.getTime() < props.maxDate.getTime() ? newDate : props.maxDate);
                } else {
                  changeMonth(newDate.getMonth());
                  if (withoutDays) {
                    newDate.setDate(1);
                    newDate.setHours(0);
                    newDate.setMinutes(0);
                    newDate.setSeconds(0);
                  }
                  onChange(newDate);
                }
              };
              return withoutDays && withTime ? null : withoutDays ? (
                <DateWithoutDaysHeader
                  date={date}
                  decreaseYear={decreaseYear}
                  increaseYear={increaseYear}
                  startYear={startYear}
                  endYear={endYear}
                />
              ) : withTime ? (
                <DateWithTimeHeader
                  date={value ? new Date(value) : new Date()}
                  startYear={startYear}
                  endYear={endYear}
                  yearsOptions={yearsOptions}
                  onChange={onChange}
                  startDate={props.startDate}
                  maxDate={props.maxDate}
                />
              ) : (
                <FullDateHeader
                  date={selected || date}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  startYear={startYear}
                  endYear={endYear}
                  yearsOptions={yearsOptions}
                  onChange={onChange}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  minDate={props.minDate}
                  maxDate={props.maxDate}
                />
              );
            }}
          />
        </DatePickerContainer>
      </Popover>
    </React.Fragment>
  );
};

export default DatePicker;
