import { ForwardedRef, HTMLAttributes, forwardRef, memo, useMemo } from 'react';
import * as Styled from './dual-slider.styles';

const MAX_VALUE_LENGTH = 5;

interface DualSliderProps extends HTMLAttributes<HTMLInputElement> {
  onMinChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMaxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  minValue: number;
  maxValue: number;
  minRange: number;
  maxRange: number;
  step?: number;
  ref?: ForwardedRef<HTMLDivElement>;
}

const DualSlider: React.FC<DualSliderProps> = forwardRef<HTMLDivElement, DualSliderProps>(
  ({ onMinChange, onMaxChange, minValue, maxValue, minRange, maxRange, step = 10, ...props }, ref) => {
    const onMinThumbChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < maxValue) return onMinChange(e);
    };

    const onMaxThumbChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) > minValue) {
        if (Number(e.target.value) > minValue && Number(e.target.value) <= maxRange) return onMaxChange(e);
      }
      return;
    };

    const minValueSting = useMemo(() => {
      return minValue.toString().length > MAX_VALUE_LENGTH
        ? `${minValue.toString().slice(0, MAX_VALUE_LENGTH)}...`
        : minValue;
    }, [minValue]);

    const maxValueSting = useMemo(() => {
      return maxValue.toString().length > MAX_VALUE_LENGTH
        ? `${maxValue.toString().slice(0, MAX_VALUE_LENGTH)}...`
        : maxValue;
    }, [maxValue]);

    return (
      <Styled.Wrapper {...props} ref={ref}>
        <Styled.FromInputLabel>{minValueSting}</Styled.FromInputLabel>
        <Styled.RangeSlider>
          <Styled.RangeSelected min={(minValue / maxRange) * 100} max={(1 - maxValue / maxRange) * 100} />
          <Styled.RangeInput>
            <Styled.RangeInputField
              className="min-range-input"
              onChange={onMinThumbChangeHandler}
              type="range"
              min={minRange}
              max={maxRange}
              value={minValue}
              step={step}
            />
            <Styled.RangeInputField
              className="max-range-input"
              onChange={onMaxThumbChangeHandler}
              type="range"
              min={minRange}
              max={maxRange}
              value={maxValue}
              step={step}
            />
          </Styled.RangeInput>
        </Styled.RangeSlider>
        <Styled.ToInputLabel>{maxValueSting}</Styled.ToInputLabel>
      </Styled.Wrapper>
    );
  },
);

export default memo(DualSlider);
