import { AxiosResponse } from 'axios';
import { $api } from '../axios/axios';
import {
  FullEmployeeInfoResponse,
  FullEmployeeRequest,
  ListItemsResponse,
  ShortEmployeeItemResponse,
  SourcingItem,
  UpdateEmployeeAuthDataRequest,
} from '@innowise-group/core';
import { RolesValues } from '@constants';

export default class EmployeesService {
  static async getEmployeeById(employeeId: string | number): Promise<AxiosResponse<ShortEmployeeItemResponse>> {
    return $api.get<ShortEmployeeItemResponse>(`/recruiting-tool/api/v1/employees/${employeeId}`);
  }

  static async getReferralsManager(): Promise<AxiosResponse> {
    return $api.get('/recruiting-tool/api/v1/employees/referral');
  }

  static async updateReferralsManager(id: string): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/employees/referral/${id}`);
  }

  static async searchEmployees(params: {
    partOfName?: string;
    page?: number;
    size?: number;
  }): Promise<AxiosResponse<ListItemsResponse<ShortEmployeeItemResponse>>> {
    return $api.get<ListItemsResponse<ShortEmployeeItemResponse>>(`/recruiting-tool/api/v1/employees/search-short`, {
      params,
    });
  }

  static async updateEmployeeAuthData(args: {
    employeeId: number;
    body: UpdateEmployeeAuthDataRequest;
  }): Promise<AxiosResponse<void>> {
    return $api.put<void>(`/recruiting-tool/api/v1/employees/authData/${args.employeeId}`, {
      ...args.body,
    });
  }

  static async getMentionedEmployees(
    candidateId: number,
  ): Promise<AxiosResponse<{ [key: string]: ShortEmployeeItemResponse }>> {
    return $api.get<{ [key: string]: ShortEmployeeItemResponse }>(
      `/recruiting-tool/api/v1/tags?candidateId=${candidateId}`,
    );
  }

  static async getEmployeeFromHrmByEmail(email: string): Promise<AxiosResponse<FullEmployeeInfoResponse>> {
    return $api.get<FullEmployeeInfoResponse>(`/recruiting-tool/api/v1/employees/notActualizedEmployee`, {
      params: { email },
    });
  }

  static async createEmployee(body: FullEmployeeRequest): Promise<AxiosResponse<void>> {
    return $api.post<void>(`/recruiting-tool/api/v1/employees/create`, {
      ...body,
    });
  }

  static async restoreEmployee(id: string | number): Promise<AxiosResponse<void>> {
    return $api.post<void>(`/recruiting-tool/api/v1/employees/restore/${id}`);
  }

  static async searchEmailByEmailPart(params: {
    email: string;
    limit: number;
    includeRtEmployees?: boolean;
  }): Promise<AxiosResponse<string[]>> {
    return $api.get<string[]>(`/recruiting-tool/api/v1/employees/searchEmailByEmailPart`, {
      params,
    });
  }

  static async deleteEmployee(id: string): Promise<AxiosResponse> {
    return $api.delete(`/recruiting-tool/api/v1/employees/${id}`);
  }

  static async searchEmployeesByRoles(roles: RolesValues[]): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/employees/role`, roles);
  }

  static async getSourcingData(params: { page: number; size?: number }): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/employees/statistic`, { params });
  }

  static async createSourcingEmployee(params: SourcingItem): Promise<AxiosResponse> {
    return $api.post(`/recruiting-tool/api/v1/employees/statistic`, {}, { params });
  }

  static async getProgressCount(): Promise<AxiosResponse> {
    return $api.get(`/recruiting-tool/api/v1/employees/profile/statistic`);
  }
}
export class EmployeeFetches {
  static prev: { [key: string]: (Date | null)[] } = {
    agreements: [],
    claims: [],
    request: [],
  };

  static setLatest(fetch: Date, index: number, claimType: string): boolean {
    this.prev[claimType][index] = fetch;
    return true;
  }
  static getLatest(fetch: Date, index: number, claimType: string): boolean {
    return this.prev[claimType][index] === fetch;
  }
}
