import { AnimationWrapper } from '../shared';
import { Button, Input, Divider, styled, Icon } from '@innowise-group/mui-kit';

export const Wrapper = styled.div`
  position: relative;
`;

export const CustomAnimationWrapper = styled(AnimationWrapper)`
  padding: 0;
  position: absolute;
  margin-top: 10px;
`;

export const CustomExpandIcon = styled(Icon)<{ expanded: boolean }>`
  transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform ${({ theme }) => theme.animation.transition};
`;

const VariantsGroupContainer = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border: 2px solid ${({ theme }) => theme.palette.general.paper};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.general.lightGrey[80]};
    border-right: 2px solid ${({ theme }) => theme.palette.general.paper};
    border-left: 2px solid ${({ theme }) => theme.palette.general.paper};
    border-top: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
    border-bottom: 2px solid ${({ theme }) => theme.palette.general.lightGrey[40]};
  }
`;

export const PreselectedVariantsGroupContainer = styled(VariantsGroupContainer)`
  max-height: 175px;
`;

export const UnselectedVariantsGroupContainer = styled(VariantsGroupContainer)`
  flex: 1;
`;

export const VariantsList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
`;

export const VariantWrapper = styled.div`
  position: relative;
`;

export const VariantContainer = styled.button<{ even?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 35px;
  padding: 0 15px;
  width: 100%;
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background: ${({ theme, even }) => (even ? theme.palette.general.darkGrey[20] : theme.palette.general.paper)};
`;

export const CommentWrapper = styled.div`
  padding: 3px;
`;

export const Select = styled.input`
  cursor: pointer;
  width: 17px;
  height: 17px;
  accent-color: ${({ theme }) => theme.palette.general.smart};
  font: inherit;
  font-size: 3px;
`;

export const Label = styled.span`
  font-family: Manrope;
  font-style: normal;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  font-size: 14px;
  width: 100%;
  text-align: left;
  display: inline-block;
  word-break: normal;
  overflow-wrap: normal;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`;

export const EmptyInputContainer = styled.div`
  height: 10px;
`;

export const CustomInput = styled(Input)`
  width: 100%;
`;

export const SelectedInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstSelectedValue = styled.p``;

export const SelectedCount = styled.p`
  background: ${({ theme }) => theme.palette.general.lightGrey[40]};
  border-radius: 50%;
  padding: 3px 5px;
  margin-left: 5px;
`;

export const FilterWrapper = styled.div`
  height: 50vh;
  width: 350px;
  border-radius: 6px;
  box-shadow: 0 0 10px 3px ${({ theme }) => theme.palette.general.lightGrey[80]};
  background: ${({ theme }) => theme.palette.general.paper};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const BottomControls = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.general.lightGrey[100]};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export const AmountLabel = styled(Label)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const CustomButton = styled(Button)`
  &.MuiButton-root.MuiButton-text {
    ${({ theme }) => theme.fonts.primary[30]}
  }
  padding: 0;
  height: auto;
  width: auto;
  white-space: nowrap;
`;

export const CustomDivider = styled(Divider)`
  margin: 2px;
`;
