import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../tooltip';
import { useModal, ApplicationBar, useTheme } from '@innowise-group/mui-kit';
import { getLanguageFromLS } from '@innowise-group/utilities';
import { AppRoutes, ManagerRolesArray, RolesValues } from '@constants';
import { VacancyCreationModal, RequestCreationFormModal } from '@innowise-group/modals';
import { useNotificationsApi, useProfileAPI, useResizeObserver } from '@innowise-group/core';
import * as Styled from './application-bar-container.styles';
import { RoleGuard } from '../role-guard';
import { GlobalSearchForm, ProfileMenu } from './components';
import { Box } from '@mui/material';
import { EventsProgress } from './components/events-progress';

const ApplicationBarContainer: React.FC = () => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isTabletView = useResizeObserver(theme.breakpoints.values.sm);
  const [showCreationVacancyModal] = useModal(VacancyCreationModal);
  const [showCreationRequestModal] = useModal(RequestCreationFormModal);

  const { profile } = useProfileAPI();
  const {
    tabs: {
      All: { count: allCount },
    },
  } = useNotificationsApi();

  useEffect(() => {
    const lang = getLanguageFromLS();
    i18n.changeLanguage(lang);
  }, [getLanguageFromLS, i18n]);

  const handleGoHome = () => {
    navigate(AppRoutes.Home);
  };

  const handleNavigate = (path: string) => () => {
    navigate(path);
  };

  const showVacancyModal = () => {
    showCreationVacancyModal({
      saveCallback: (vacancyId: string) => {
        navigate(`${AppRoutes.Vacancies}/${vacancyId}`);
      },
    });
  };

  const successCreationRequestCallback = (requestId: number) => {
    navigate(`${AppRoutes.Requests}/${requestId}`);
  };

  const showRequestModal = () => {
    showCreationRequestModal({ successCreationCallback: successCreationRequestCallback });
  };

  const redirectToNotificationsPage = () => {
    navigate(AppRoutes.Notifications);
  };

  if (!profile) return null;

  return (
    <ApplicationBar onLogoClick={handleGoHome}>
      {isTabletView ? (
        <RoleGuard roles={[RolesValues.Admin, RolesValues.Supervisor, RolesValues.Recruiter]}>
          <GlobalSearchForm />
        </RoleGuard>
      ) : (
        <Styled.SearchButton onClick={handleNavigate(AppRoutes.SearchCandidates)} variant="text">
          <Styled.SearchIcon type="u_search" size={30} viewBox="0 -4 26 26" />
        </Styled.SearchButton>
      )}
      <Styled.TogglesContainer>
        {isTabletView && (
          <React.Fragment>
            <Styled.ButtonsContainer>
              <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
                <Tooltip title={t('pages.profile.profileDetails.lettersCount')} placement="bottom">
                  <div>
                    <EventsProgress />
                  </div>
                </Tooltip>
              </RoleGuard>
              <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
                <Tooltip title={t('tooltips.addCandidate')} placement="bottom">
                  <Box>
                    <Styled.PlusIcon
                      type="u_plus_candidate"
                      onClick={handleNavigate(AppRoutes.CandidateCreation)}
                      size={40}
                      viewBox="-3 -3 26 26"
                    />
                  </Box>
                </Tooltip>
              </RoleGuard>
              <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
                <Tooltip title={t('tooltips.addVacancy')} placement="bottom">
                  <Box>
                    <Styled.PlusIcon type="u_plus_vacancy" size={40} viewBox="-5 -7 33 33" onClick={showVacancyModal} />
                  </Box>
                </Tooltip>
              </RoleGuard>
              <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer, ...ManagerRolesArray]}>
                <Tooltip title={t('tooltips.addRequest')} placement="bottom">
                  <Box>
                    <Styled.PlusIcon type="u_plus_request" size={40} viewBox="-3 -5 29 29" onClick={showRequestModal} />
                  </Box>
                </Tooltip>
              </RoleGuard>
            </Styled.ButtonsContainer>
            <Styled.Divider />
          </React.Fragment>
        )}
        <Styled.NotificationButton>
          <Styled.CustomIcon type="u_union" size={35} viewBox="0 0 33 25" onClick={redirectToNotificationsPage} />
          {!!allCount && <Styled.NotificationCount>{allCount}</Styled.NotificationCount>}
        </Styled.NotificationButton>
        <ProfileMenu showRequestModal={showRequestModal} showVacancyModal={showVacancyModal} />
      </Styled.TogglesContainer>
    </ApplicationBar>
  );
};

export default ApplicationBarContainer;
