import { Chip as MuiChip } from '../chip';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { Loader as LoaderBase } from '../loader';
import { styled } from '@innowise-group/mui-kit';
import { SelectOption } from '@innowise-group/core';

export const Autocomplete = styled(MuiAutocomplete)`
  & .MuiOutlinedInput-root {
    padding: ${({ multiple }) => (multiple ? '0.6875rem 1.25rem' : '0.75rem 1.25rem')} !important;
    padding-right: ${({ disableClearable }) => (disableClearable ? 1.875 : 3.4375)}rem !important;
    row-gap: 3px;
    min-height: 2.75rem;
  }
  & li.MuiAutocomplete-option {
    ${({ theme }) => theme.fonts.primary[20]};
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
    padding: 9px 16px;
    border: 1px solid red !important;
  }
  & input {
    padding: 0 !important;
    height: 1.25rem;
  }
  & .MuiInputLabel-root {
    top: ${({ value, multiple }) => {
      if (multiple) return (value as SelectOption[]).length ? '4px' : '-3px';
      return value ? '4px' : '-4px';
    }};
    line-height: 1.25;
    padding-right: ${({ value }) => {
      return value ? 0 : '1.875rem';
    }};
    &.Mui-focused {
      top: 4px;
    }
  }
  & .MuiAutocomplete-clearIndicator {
    visibility: visible;
    padding: 2px;
  }
  & .MuiAutocomplete-endAdornment {
    top: calc(50%);
    transform: translateY(-50%);
  }
  & .MuiAutocomplete-tagSizeMedium {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 22px;
    height: 22px;
    ${({ theme }) => theme.fonts.primary[20]};
    border-radius: 11px;
    padding: 3px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.general.darkGrey[60] : theme.palette.general.darkGrey[100]};
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]} !important;
  }
`;

export const OptionItem = styled.li<{ selected: boolean }>`
  ${({ theme, selected }) => (selected ? theme.fonts.primary[30] : theme.fonts.primary[20])};
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  padding: 9px 16px;
  background-color: ${({ theme, selected }) => selected && theme.palette.state.error.background} !important;
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]} !important;
  }
`;

export const CheckboxOptionItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  gap: 15px;
  padding: 9px 16px;
  ${({ theme }) => theme.fonts.primary[20]};
  background-color: transparent !important;
  &:hover {
    background-color: ${({ theme }) => theme.palette.general.darkGrey[20]} !important;
  }
`;

export const Label = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LimitTagsChip = styled(MuiChip)`
  &.MuiChip-root {
    min-height: 1.375rem;
    height: 1.375rem;
  }
  & .MuiChip-label {
    padding: 0 5px;
  }
`;

export const Chip = styled(MuiChip)`
  & .MuiChip-deleteIcon {
    font-size: 1.125rem !important;
  }
  &.MuiChip-root {
    min-height: 1.375rem;
    height: 1.375rem;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled(LoaderBase)`
  margin: 0;
`;
