import {
  ContactsType,
  FileTypeConstants,
  FileTypesEnum,
  candidateFormDefaultValues,
  CandidateSourceEmailId,
  FileStatusTypeConstants,
  ClaimActionTypes,
  DateFormats,
  CandidateLifecycleStatuses,
} from '@constants';
import {
  AppReducerType,
  CandidateCommentItem,
  FileIdResponse,
  CandidateFileItemResponse,
  CandidateItem,
  CandidateItemResponse,
  CandidatesService,
  FilesService,
  MyKnownError,
  CandidateHistoryResponse,
  HistoryCommentItem,
  CandidateHistoryCommentItemResponse,
  ShortCandidateItemResponse,
  CandidateReportItem,
  ListItemsResponse,
  RecruiterModeItem,
  RequestsService,
  GetAllCandidates,
  StatusesService,
  CandidateStatusListItem,
  StatusItem,
  StatusItemResponse,
  mapStatusItemResponseToStatusItem,
  CandidateEventCommentResponse,
  LocalizedCandidateItemResponse,
  mapCandidateItemResponseToCandidateItem,
  prioritizedCandidatesMapper,
  OffersService,
  CandidateOfferItem,
  CandidateOfferItemResponse,
  RequestItemResponse,
} from '@innowise-group/core';
import {
  calculateDateWithoutTimeZone,
  candidateDataThunkTransform,
  getDateStringWithoutTimezone,
} from '@innowise-group/utilities';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { format } from 'date-fns';

export const createCandidateAvatarPhotoThunk = createAsyncThunk<
  FileIdResponse,
  {
    file: File;
    successCallback?: () => void;
  },
  { rejectValue: MyKnownError }
>('candidate/createAvatar', async ({ file, successCallback }, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await FilesService.createFile(formData, false, FileTypeConstants.CandidatePhoto);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createCandidateThunk = createAsyncThunk<
  CandidateItemResponse,
  { data: CandidateItem; successCallback?: (id: string) => void },
  { rejectValue: MyKnownError }
>('candidates/create', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const newData = candidateDataThunkTransform(data);
    const response = await CandidatesService.createCandidate(newData);
    successCallback?.(response.data.id);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const attachDuplicatesIdsToCandidateThunk = createAsyncThunk<
  void,
  { candidateId: number | string; duplicateIds: (string | number)[]; successCallback?: (id: string | number) => void },
  { rejectValue: MyKnownError }
>(
  'candidates/attachDuplicatesIdsToCandidate',
  async ({ candidateId, duplicateIds, successCallback }, { rejectWithValue }) => {
    try {
      await CandidatesService.candidateDuplicatesCreate({ candidateId, duplicateIds });
      successCallback?.(candidateId);
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const checkCandidatesDuplicatesThunk = createAsyncThunk<
  { duplicates: CandidateItemResponse[]; isExists: boolean },
  CandidateItem,
  { rejectValue: MyKnownError }
>('candidate-duplicates/check-candidate', async (data, { rejectWithValue }) => {
  try {
    const newData = candidateDataThunkTransform(data);
    const response = await CandidatesService.checkCheckCandidateDuplicates({
      ...newData,
      candidateExperiences: newData.candidateExperiences.map((ex) => ({
        from: ex.workedFrom,
        to: ex.workedTo,
      })),
      candidateCourses: newData.candidateCourses.map((ex) => ({
        from: ex.studiedFrom,
        to: ex.studiedTo,
      })),
      candidateEducations: newData.candidateEducations.map((ex) => ({
        from: ex.studiedFrom,
        to: ex.studiedTo,
      })),
    });
    return {
      duplicates: response.data,
      isExists: !!response.data?.length,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const findExistingCandidateDuplicatesThunk = createAsyncThunk<
  { duplicatesIds: string[]; isExists: boolean },
  { candidate: CandidateItem; id?: string },
  { rejectValue: MyKnownError }
>('candidate-duplicates/find-existing-candidate-duplicate', async ({ candidate, id }, { rejectWithValue }) => {
  try {
    const newData = candidateDataThunkTransform(candidate);
    const response = await CandidatesService.checkCheckCandidateDuplicates({
      ...newData,
      candidateExperiences: newData.candidateExperiences.map((ex) => ({
        from: ex.workedFrom,
        to: ex.workedTo,
      })),
      candidateCourses: newData.candidateCourses.map((ex) => ({
        from: ex.studiedFrom,
        to: ex.studiedTo,
      })),
      candidateEducations: newData.candidateEducations.map((ex) => ({
        from: ex.studiedFrom,
        to: ex.studiedTo,
      })),
    });

    const duplicatesIds = response.data.reduce<string[]>(
      (acc, { id: duplicateId }) =>
        duplicateId?.toString() !== id?.toString() ? [...acc, duplicateId.toString()] : acc,
      [],
    );
    return {
      duplicatesIds,
      isExists: !!duplicatesIds?.length,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateCandidateThunk = createAsyncThunk<
  AxiosResponse,
  {
    data: CandidateItem;
    id: number;
    currentCandidate?: LocalizedCandidateItemResponse;
    successCallback?: () => void;
  },
  { rejectValue: MyKnownError }
>('candidates/update', async ({ data, id, currentCandidate, successCallback }, { rejectWithValue }) => {
  try {
    const newData = candidateDataThunkTransform(data, currentCandidate);
    const res = await CandidatesService.updateCandidate(newData, id);
    successCallback?.();
    return res;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createCandidateFileIdThunk = createAsyncThunk<
  FileIdResponse,
  { file: File; isProtected: boolean; successCallback?: (file?: FileIdResponse) => void; isStatusFile?: boolean },
  { rejectValue: MyKnownError }
>('candidatesFile/createId', async ({ file, isProtected, successCallback }, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await FilesService.createFile(formData, isProtected, FileTypeConstants.CandidateFiles);
    successCallback?.(response.data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteCandidateFileIdThunk = createAsyncThunk<
  number,
  { attachmentId: number; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidatesFile/deleteId', async ({ attachmentId, successCallback }, { rejectWithValue }) => {
  try {
    await FilesService.deleteFile(attachmentId);
    successCallback?.();
    return attachmentId;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateFilesIdsThunk = createAsyncThunk<
  CandidateFileItemResponse[],
  { candidateId: number; candidateLifecycleStatus: CandidateLifecycleStatuses },
  { rejectValue: MyKnownError }
>('candidatesFile/getIds', async ({ candidateId, candidateLifecycleStatus }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.getFilesIds(candidateId, candidateLifecycleStatus);
    return response.data.filter((item) => item.fileStatus === FileStatusTypeConstants.Attached);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const linkCandidateFileThunk = createAsyncThunk<
  CandidateItemResponse,
  { fileId: number; currentCandidate: LocalizedCandidateItemResponse },
  { rejectValue: MyKnownError }
>('candidatesFile/linkFile', async ({ fileId, currentCandidate }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as AppReducerType;
    const initCandidate = mapCandidateItemResponseToCandidateItem(state.candidates.candidates.currentCandidate);
    const candidate = candidateDataThunkTransform(initCandidate, currentCandidate);
    const newCandidate = { ...candidate, attachmentIds: [...candidate.attachmentIds, fileId] };
    const response = await CandidatesService.updateCandidate(newCandidate, initCandidate.id);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateFileDataThunk = createAsyncThunk<
  string | void,
  { attachmentId: number; fileName: string; closeCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidatesFile/getFileData', async ({ attachmentId, fileName, closeCallback }, { rejectWithValue }) => {
  try {
    await FilesService.checkFilePermission(attachmentId);
    const response = await FilesService.getFileAttachment(attachmentId);
    if (response.data.byteLength) {
      const data = URL.createObjectURL(
        new Blob([response.data], {
          type: FileTypesEnum[fileName.split('.').reverse()[0]],
        }),
      );
      return data;
    } else {
      closeCallback?.();
    }
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getAllCandidatesThunk = createAsyncThunk<
  ListItemsResponse<ShortCandidateItemResponse>,
  | Parameters<typeof CandidatesService.candidateSearch>[0]
  | Parameters<typeof CandidatesService.getPrioritizedCandidates>[0],
  { rejectValue: MyKnownError }
>('candidates/getAll', async (data, { rejectWithValue }) => {
  try {
    let response: AxiosResponse<GetAllCandidates>;
    if (data.params.isRecruiterMode) {
      response = await CandidatesService.getPrioritizedCandidates(data);
    } else {
      response = await CandidatesService.candidateSearch(data);
    }
    if (data.body?.['lifecycleStatus']?.includes(CandidateLifecycleStatuses.Deleted)) {
      const arr: Promise<AxiosResponse>[] = [];
      response.data.content.map((item) => arr.push(RequestsService.checkCandidateHistory(item.id)));
      const checkedResponse = await Promise.all(arr);
      return {
        ...response.data,
        content: response.data.content.map((item, index) => {
          return { ...item, hasRequestHistory: checkedResponse[index].data || null };
        }),
      };
    }
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateByIdThunk = createAsyncThunk<CandidateItemResponse, number, { rejectValue: MyKnownError }>(
  'candidates/getItem',
  async (id, { rejectWithValue }) => {
    try {
      const response = await CandidatesService.getCandidateById(id);
      return {
        ...response.data,
        ...(response.data.birthDate && { birthDate: getDateStringWithoutTimezone(response.data.birthDate) }),
        aboutMe: response.data.aboutMe || '',
        vacancyIdWithRequestAttached: response.data.vacancyIdWithRequestAttached?.toString(),
      };
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const restoreCandidateByIdThunk = createAsyncThunk<
  CandidateItemResponse,
  { ids: number[] },
  { rejectValue: MyKnownError }
>('candidates/restore', async ({ ids }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.restoreCandidateById(ids);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteCandidateByIdThunk = createAsyncThunk<
  void,
  { id: number; isSoftDelete: boolean; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/deleteItem', async ({ id, isSoftDelete, successCallback }, { rejectWithValue }) => {
  try {
    await CandidatesService.deleteCandidateById(id, isSoftDelete);
    successCallback?.();
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createCandidateCommentThunk = createAsyncThunk<
  StatusItemResponse,
  { data: CandidateCommentItem; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/createComment', async ({ data, successCallback }) => {
  try {
    const newData: StatusItem = {
      hasReminder: false,
      claims: [],
      candidateId: data.candidateId,
      ...(Number(data.linkedVacancyId) && { vacancyId: Number(data.linkedVacancyId) }),
      comment: {
        comment: data.comment,
        isProtected: data.isProtected,
      },
    };
    const response = await StatusesService.createStatus(newData);
    successCallback?.();
    return { ...response.data, comment: { ...response.data.comment, isLoading: false } };
  } catch (err) {
    return err;
  }
});

export const createCandidateThreadCommentThunk = createAsyncThunk<
  CandidateEventCommentResponse,
  { eventId: number; data: CandidateCommentItem; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/createThreadComment', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      comment: data.comment,
      isProtected: data.isProtected,
      parentId: data.parentId,
    };
    const response = await StatusesService.createThread(newData);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const restoreAdminEventThunk = createAsyncThunk<
  StatusItemResponse,
  { eventId: number; parentId?: number },
  { rejectValue: MyKnownError }
>('candidates/restoreAdminComment', async ({ eventId, parentId }, { rejectWithValue }) => {
  try {
    if (parentId) {
      const response = await StatusesService.restoreThread(eventId);
      return response.data;
    }
    const response = await StatusesService.restoreEvent(eventId);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateCandidateCommentThunk = createAsyncThunk<
  StatusItemResponse,
  { eventId: number; data: CandidateCommentItem; successCallback: () => void },
  { rejectValue: MyKnownError }
>('candidates/updateComment', async ({ eventId, data, successCallback }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as AppReducerType;
    const event = mapStatusItemResponseToStatusItem(state.candidates.events.items[eventId.toString()]);
    const newData: StatusItem = {
      ...event,
      vacancyId: Number(data.linkedVacancyId) || null,
      claims:
        event.claims?.reduce((accum, curr) => {
          if (curr?.employeeId) {
            delete curr.employeeFullName;
            accum.push(curr);
          }
          return accum;
        }, []) || [],
      comment: {
        comment: data.comment,
        isProtected: data.isProtected,
        id: event.comment.id,
      },
    };
    const response = await StatusesService.updateStatus(newData);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateCandidateThreadCommentThunk = createAsyncThunk<
  CandidateEventCommentResponse,
  { eventId: number; data: CandidateCommentItem; successCallback: () => void },
  { rejectValue: MyKnownError }
>('candidates/updateThreadComment', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      comment: data.comment,
      isProtected: data.isProtected,
      id: data.id,
    };
    const response = await StatusesService.updateThread(newData);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteCandidateCommentsThunk = createAsyncThunk<
  CandidateItemResponse,
  { candidateId: number; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/deleteComment', async ({ candidateId, successCallback }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as AppReducerType;
    const deletedIds = state.candidates.events.deletedIds;
    const deletedThreadIds = state.candidates.events.deletedThreadIds;
    if (deletedIds.length) {
      const arr: Promise<AxiosResponse>[] = [];
      deletedIds.map((item) => arr.push(StatusesService.deleteStatus(item)));
      await Promise.all(arr);
    }
    if (deletedThreadIds.length) {
      const arr: Promise<AxiosResponse>[] = [];
      deletedThreadIds.map((item) => arr.push(StatusesService.deleteThread(item)));
      await Promise.all(arr);
    }
    let candidateResponse = null;
    if (state.candidates.candidates.currentCandidate) {
      candidateResponse = await CandidatesService.getCandidateById(candidateId);
    }
    successCallback?.();
    return candidateResponse.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidatePdfParsedInfoThunk = createAsyncThunk<
  FileIdResponse,
  { file: File; successCallback: (data: CandidateItem, fullResume: string) => void; errorCallback: () => void },
  { rejectValue: MyKnownError }
>('candidates/getParsedPdfInfo', async ({ file, successCallback, errorCallback }, { rejectWithValue, getState }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await CandidatesService.getParsedPdfInfo(formData);
    const fileResponse = await FilesService.createFile(formData, false, FileTypeConstants.CandidateFiles);

    const state = getState() as AppReducerType;
    const data: CandidateItem = {
      ...candidateFormDefaultValues,
      firstNameRu: response.data.firstNameRu || '',
      lastNameRu: response.data.lastNameRu || '',
      firstNameEn: response.data.firstNameEn || '',
      lastNameEn: response.data.lastNameEn || '',
      email: response.data.email || '',
      sourceId: CandidateSourceEmailId,
      filesMeta: [fileResponse.data],
      responsibleEmployee: state.profile.item.id.toString(),
      candidateContacts: candidateFormDefaultValues.candidateContacts.map((item, index) => {
        if (item.contactType === ContactsType.GITHUB && response.data.gitHub) {
          return {
            contactType: ContactsType.GITHUB,
            contact: { value: response.data.gitHub },
          };
        }
        if (item.contactType === ContactsType.LINKEDIN && response.data.linkedIn) {
          return {
            contactType: ContactsType.LINKEDIN,
            contact: { value: response.data.linkedIn },
          };
        }
        if (item.contactType === ContactsType.TELEGRAM && response.data.telegram) {
          return {
            contactType: ContactsType.TELEGRAM,
            contact: { value: response.data.telegram },
          };
        }
        if (
          item.contactType === ContactsType.PHONE &&
          response.data.phone &&
          index !== candidateFormDefaultValues.candidateContacts.length - 1
        ) {
          return {
            contactType: ContactsType.PHONE,
            contact: { value: response.data.phone.split(/[-()+\s]/).join('') },
          };
        } else {
          return item;
        }
      }),
    };
    successCallback(data, response.data.fullResume);
    return fileResponse.data;
  } catch (err) {
    if ((err as AxiosError).request?.status === 423) {
      errorCallback();
    }
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateChangeHistoryThunk = createAsyncThunk<
  { items: CandidateHistoryResponse; withComments: number[] },
  { candidateId: number; page: number },
  { rejectValue: MyKnownError }
>('candidates/getChangeHistory', async ({ candidateId, page }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.getChangeHistoryByCandidate(candidateId, page);
    let withCommentsIdsResponse;
    if (page <= 1) {
      withCommentsIdsResponse = await CandidatesService.getChangeHistoryWithCommentsIds({ candidateId });
    }
    const data = {
      ...response.data,
      content: response.data.content.map((item) => {
        return {
          ...item,
          hasComments: false,
          comments: { isLoading: false, ids: [], deletedIds: [], items: {} },
        };
      }),
    };
    return { items: data, withComments: withCommentsIdsResponse?.data || [] };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const restoreAdminChangeCommentThunk = createAsyncThunk<
  undefined,
  { commentId: number; parentId?: number },
  { rejectValue: MyKnownError }
>('candidates/restoreAdminHistoryChangeComment', async ({ commentId }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.restoreChangeHistoryComment([commentId]);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createHistoryCommentThunk = createAsyncThunk<
  CandidateHistoryCommentItemResponse,
  { data: HistoryCommentItem; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/createHistoryComment', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.createChangeHistoryComment(data);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const fetchCandidatesPairByIdThunk = createAsyncThunk<
  {
    targetCandidate: Awaited<ReturnType<typeof CandidatesService.getCandidateById>>['data'];
    updatesCandidate: Awaited<ReturnType<typeof CandidatesService.getCandidateById>>['data'];
  },
  {
    targetCandidateId: number;
    updatesCandidateId: number;
  },
  { rejectValue: MyKnownError }
>('candidates/fetchCandidatesPairById', async ({ targetCandidateId, updatesCandidateId }, { rejectWithValue }) => {
  try {
    const { data: targetCandidate } = await CandidatesService.getCandidateById(targetCandidateId);
    const { data: updatesCandidate } = await CandidatesService.getCandidateById(updatesCandidateId);
    return {
      targetCandidate,
      updatesCandidate,
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const duplicatesReportThunk = createAsyncThunk<
  ListItemsResponse<CandidateReportItem>,
  Parameters<typeof CandidatesService.getDuplicatesReport>[0],
  { rejectValue: MyKnownError }
>('candidates/getDuplicatesReport', async (data, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.getDuplicatesReport(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateHistoryCommentThunk = createAsyncThunk<
  CandidateHistoryCommentItemResponse,
  { data: HistoryCommentItem; successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/updateHistoryComment', async ({ data, successCallback }, { rejectWithValue }) => {
  try {
    const newData = {
      id: data.id,
      comment: data.comment,
      isProtected: data.isProtected,
    };
    const response = await CandidatesService.updateChangeHistoryComment(newData);
    successCallback?.();
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const resolveCandidateDuplicatesThunk = createAsyncThunk<
  void,
  Parameters<typeof CandidatesService.resolveCandidateDuplicates>[0] & { successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/resolveCandidateDuplicates', async ({ groupId, successCallback }, { rejectWithValue }) => {
  try {
    await CandidatesService.resolveCandidateDuplicates({ groupId });
    if (successCallback) successCallback();
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getHistoryCommentThunk = createAsyncThunk<
  CandidateHistoryCommentItemResponse[],
  { changeStoryGroupId: number },
  { rejectValue: MyKnownError }
>('candidates/getHistoryComments', async ({ changeStoryGroupId }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.getChangeHistoryComments({ changeStoryGroupId });
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const mergeCandidateDuplicatesThunk = createAsyncThunk<
  void,
  {
    data: CandidateItem;
    currentCandidate: LocalizedCandidateItemResponse;
    groupId: string;
    candidateId: string;
    candidateDuplicateId: string;
  },
  { rejectValue: MyKnownError }
>('candidates/mergeCandidateDuplicates', async ({ data, currentCandidate, ...rest }, { rejectWithValue }) => {
  try {
    const validCandidate = candidateDataThunkTransform(data, currentCandidate);
    await CandidatesService.mergeCandidateDuplicates({
      ...rest,
      validCandidate,
    });
    return;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteHistoryCommentsThunk = createAsyncThunk<
  void,
  { successCallback?: () => void },
  { rejectValue: MyKnownError }
>('candidates/deleteHistoryComment', async ({ successCallback }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as AppReducerType;
    const deletedIds = state.candidates.history.ids.reduce((accum, item) => {
      return accum.concat(state.candidates.history.items[item].comments.deletedIds);
    }, []);
    if (deletedIds.length) {
      const arr: Promise<AxiosResponse>[] = [];
      deletedIds.map((item) => arr.push(CandidatesService.deleteChangeHistoryComment(item)));
      await Promise.all(arr);
    }
    successCallback?.();
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const candidatesExportThunk = createAsyncThunk<
  void,
  Parameters<typeof CandidatesService.candidatesExport>[0],
  { rejectValue: MyKnownError }
>('candidates/export', async (args, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.candidatesExport(args);
    const data = URL.createObjectURL(response.data);
    const fileUrl = document.createElement('a');
    fileUrl.href = data;
    fileUrl.download = 'export-results.xlsx';
    document.body.appendChild(fileUrl);
    fileUrl.click();
    URL.revokeObjectURL(fileUrl.href);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCountryByPhoneThunk = createAsyncThunk<string, { phone: string }, { rejectValue: MyKnownError }>(
  'country/getByPhone',
  async ({ phone }, { rejectWithValue }) => {
    try {
      const response = await CandidatesService.getCountryByPhone(phone);
      return response.data.isoCode;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const prioritizeCandidatesThunk = createAsyncThunk<
  void,
  { selectedCandidates: number[]; data: RecruiterModeItem },
  { rejectValue: MyKnownError }
>('candidates/prioritize', async ({ data, selectedCandidates }, { rejectWithValue }) => {
  try {
    if (!data.requestId) await CandidatesService.prioritizeCandidates(selectedCandidates, Number(data.vacancyId), true);
    if (data.requestId) await RequestsService.createVacancyResponses(Number(data.requestId), selectedCandidates);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getPrioritizeCandidatesThunk = createAsyncThunk<
  ListItemsResponse<ShortCandidateItemResponse>,
  { data: Parameters<typeof CandidatesService.getPrioritizedCandidates>[0]; controller: AbortController },
  { rejectValue: MyKnownError }
>('candidates/getPrioritized', async ({ data, controller }, { rejectWithValue }) => {
  try {
    const response = await CandidatesService.getPrioritizedCandidates(data, controller);
    return {
      ...response.data,
      content: response.data.content.map((item) => {
        return { ...item, isBoosted: data.params.boosted };
      }),
    };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getPrioritizeCandidatesByVacancyIdThunk = createAsyncThunk<
  ListItemsResponse<ShortCandidateItemResponse>,
  { params: Parameters<typeof CandidatesService.getPrioritizeCandidatesByVacancyId>[0]; controller: AbortController },
  { rejectValue: MyKnownError }
>('candidates/getPrioritizeCandidatesByVacancyId', async ({ params, controller }, { rejectWithValue }) => {
  try {
    const { data } = await CandidatesService.getPrioritizeCandidatesByVacancyId(params, controller);
    return prioritizedCandidatesMapper(data);
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getStatusListThunk = createAsyncThunk<CandidateStatusListItem[], undefined, { rejectValue: MyKnownError }>(
  'candidates/getStatusList',
  async (data, { rejectWithValue }) => {
    try {
      const response = await StatusesService.getStatusList();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const updateCandidateStatusThunk = createAsyncThunk<
  StatusItemResponse,
  StatusItem,
  { rejectValue: MyKnownError }
>('candidates/updateStatus', async (data, { rejectWithValue }) => {
  try {
    const claims =
      data.claims?.reduce((accum, curr) => {
        if (curr?.employeeId) {
          delete curr.employeeFullName;
          accum.push({
            ...curr,
            deadline: curr.deadline ? format(calculateDateWithoutTimeZone(curr.deadline), DateFormats.ISO) : null,
          });
        }
        return accum;
      }, []) || [];
    data.agreements.map((item) => {
      if (item?.employeeId) {
        delete item.employeeFullName;
        claims.push({
          ...item,
          deadline: item.deadline ? format(calculateDateWithoutTimeZone(item.deadline), DateFormats.ISO) : null,
        });
      }
    });
    delete data.agreements;

    const newData = {
      ...data,
      fileId: data.file?.id || null,
      link: data.link?.name ? data.link : null,
      subStatusId: data.subStatusId || null,
      hasReminder: !!data.reminderDate,
      reminderDate: data.reminderDate ? format(calculateDateWithoutTimeZone(data.reminderDate), DateFormats.ISO) : null,
      eventDate: data.eventDate ? format(calculateDateWithoutTimeZone(data.eventDate), DateFormats.ISO) : null,
      comment: { ...data.comment, isProtected: data.comment.comment ? data.comment.isProtected : false },
      claims,
    };
    const response = await StatusesService.updateStatus(newData);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateVacancyCandidatePriorityThunk = createAsyncThunk<
  void,
  { vacancyId: number; candidateId: number; newBoostedValue: boolean },
  { rejectValue: MyKnownError }
>(
  'candidates/updateVacancyCandidatePriority',
  async ({ vacancyId, candidateId, newBoostedValue }, { rejectWithValue }) => {
    try {
      await CandidatesService.prioritizeCandidates([candidateId], vacancyId, newBoostedValue);
    } catch (err) {
      return rejectWithValue({
        errorMessage: err.message,
      } as MyKnownError);
    }
  },
);

export const createCandidateStatusThunk = createAsyncThunk<
  StatusItemResponse,
  StatusItem,
  { rejectValue: MyKnownError }
>('candidates/createStatus', async (data, { rejectWithValue }) => {
  try {
    const claims =
      data.claims?.reduce((accum, curr) => {
        if (curr?.employeeId) {
          delete curr.employeeFullName;
          accum.push({
            ...curr,
            deadline: curr.deadline ? format(calculateDateWithoutTimeZone(curr.deadline), DateFormats.ISO) : null,
          });
        }
        return accum;
      }, []) || [];
    data.agreements?.map((item) => {
      if (item?.employeeId) {
        delete item.employeeFullName;
        claims.push({
          ...item,
          deadline: item.deadline ? format(calculateDateWithoutTimeZone(item.deadline), DateFormats.ISO) : null,
        });
      }
    });
    delete data.agreements;
    const newData = {
      ...data,
      hasReminder: !!data.reminderDate,
      subStatusId: data.subStatusId || null,
      periodFrom: data.periodFrom
        ? format(new Date(new Date(data.periodFrom).setHours(23, 59, 59, 999)), DateFormats.ISO)
        : null,
      periodTo: data.periodTo
        ? format(new Date(new Date(data.periodTo).setHours(23, 59, 59, 999)), DateFormats.ISO)
        : null,
      reminderDate: data.reminderDate ? format(calculateDateWithoutTimeZone(data.reminderDate), DateFormats.ISO) : null,
      eventDate: data.eventDate ? format(calculateDateWithoutTimeZone(data.eventDate), DateFormats.ISO) : null,
      link: data.link?.url ? data.link : null,
      fileId: data.file?.id || null,
      comment: { ...data.comment, isProtected: data.comment.comment ? data.comment.isProtected : false },
      claims,
    };

    const response = await StatusesService.createStatus(newData);
    return { ...response.data, comment: { ...response.data.comment, isLoading: false } };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateEventsThunk = createAsyncThunk<
  ListItemsResponse<StatusItemResponse>,
  { candidateId: number; page: number; candidateLifecycleStatus: CandidateLifecycleStatuses },
  { rejectValue: MyKnownError }
>('candidates/getEvents', async ({ candidateId, page, candidateLifecycleStatus }, { rejectWithValue }) => {
  try {
    const response = await StatusesService.getCandidateEvents(candidateId, page, candidateLifecycleStatus);
    const data = response.data.content.map((item) => {
      return { ...item, comment: { ...item.comment, isLoading: false } };
    });
    return { ...response.data, content: data };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const resolveStatusClaimThunk = createAsyncThunk<
  CandidateItemResponse,
  {
    eventId: number;
    actionType: ClaimActionTypes;
    claimId: number;
    file?: FileIdResponse;
    candidateId?: number;
  },
  { rejectValue: MyKnownError }
>('candidates/resolveStatusClaim', async ({ claimId, actionType, file, candidateId }, { rejectWithValue }) => {
  try {
    const newData = { actionType, parameters: { claimId, fileId: file?.id } };
    await StatusesService.resolveStatusClaim(newData);
    if (file && candidateId) {
      const response = await CandidatesService.getCandidateById(candidateId);
      return response.data;
    }
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getCandidateActualStatusThunk = createAsyncThunk<
  StatusItemResponse,
  { candidateId: number },
  { rejectValue: MyKnownError }
>('candidates/getActualStatus', async ({ candidateId }, { rejectWithValue }) => {
  try {
    const response = await StatusesService.getActualStatus(candidateId);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const getOffersByCandidateIdThunk = createAsyncThunk<
  CandidateOfferItemResponse[],
  { candidateId: number },
  { rejectValue: MyKnownError }
>('candidates/getOffersByCandidateId', async ({ candidateId }, { rejectWithValue }) => {
  try {
    const response = await OffersService.getCandidateOffers(candidateId);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const createCandidateOfferThunk = createAsyncThunk<
  CandidateOfferItemResponse,
  { data: CandidateOfferItem },
  { rejectValue: MyKnownError }
>('candidates/createCandidateOffer', async ({ data }, { rejectWithValue }) => {
  try {
    const response = await OffersService.createOffer(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const updateCandidateOfferThunk = createAsyncThunk<
  CandidateOfferItemResponse,
  { data: CandidateOfferItem },
  { rejectValue: MyKnownError }
>('candidates/updateCandidateOffer', async ({ data }, { rejectWithValue }) => {
  try {
    const response = await OffersService.updateOffer(data);
    return response.data;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const deleteCandidateOfferThunk = createAsyncThunk<
  { id: number },
  { id: number },
  { rejectValue: MyKnownError }
>('candidates/deleteCandidateOffer', async ({ id }, { rejectWithValue }) => {
  try {
    await OffersService.deleteOffer(id);
    return { id: Number(id) };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const restoreCandidateOfferThunk = createAsyncThunk<
  { id: number },
  { id: number },
  { rejectValue: MyKnownError }
>('candidates/restoreCandidateOffer', async ({ id }, { rejectWithValue }) => {
  try {
    await OffersService.restoreOffer(id);
    return { id: Number(id) };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const fetchCandidateRequestClaimThunk = createAsyncThunk<
  { withRequestClaim?: RequestItemResponse },
  { candidateId: string },
  { rejectValue: MyKnownError }
>('candidates/getRequestClaim', async ({ candidateId }, { rejectWithValue }) => {
  try {
    const response = await RequestsService.getRequestByCandidateId(candidateId);
    return { withRequestClaim: response.data || null };
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});

export const saveStatusesOrdersThunk = createAsyncThunk<
  string[],
  { ordersIds: string[] },
  { rejectValue: MyKnownError }
>('candidates/changeStatusesOrders', async ({ ordersIds }, { rejectWithValue }) => {
  try {
    const data: { [key: string]: number | null } = ordersIds.reduce((acc, val, index) => {
      return { ...acc, [val]: index + 1 };
    }, {});
    await StatusesService.setStatusesOrders(data);
    return ordersIds;
  } catch (err) {
    return rejectWithValue({
      errorMessage: err.message,
    } as MyKnownError);
  }
});
