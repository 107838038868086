import { memo, useState } from 'react';
import {
  NumberRangeFilter,
  TextFilter,
  BooleanFilter,
  SalaryFilter,
  MultiSelectFilter,
  DateRangeFilter,
  FiltersManager,
  NestedSelectFilter,
  ActivityPlaceFilter,
  DateSegmentFilter,
  EmployeFilter as EmployeeFilter,
  VacancyFilter,
  UnpinnedSearchFilter,
} from './components';
import { useTranslation } from 'react-i18next';
import { FiltersEntity, FiltersTypes } from '@innowise-group/core';
import * as Styled from './desktop-view.styles';
import { RolesValues } from '@constants';
import { RoleGuard } from '@shared-components';
import { LabeledSwitch } from '@shared-mui-components';
import { FiltersProps } from '../../filters.types';

const DesktopView: React.FC<FiltersProps> = ({
  filters,
  isDisabledFiltersButtons,
  addAndOpenFilter,
  closeFilter,
  removeFilter,
  toggleFilter,
  onResetFilters,
  onSubmitFilter,
  handleChangeRecruiterMode,
  isRecruiterMode,
  withRecruiterMode,
  onFilterChange,
}) => {
  const FilterMap = {
    [FiltersTypes.Text]: TextFilter,
    [FiltersTypes.Money]: SalaryFilter,
    [FiltersTypes.Select]: MultiSelectFilter,
    [FiltersTypes.Vacancy]: VacancyFilter,
    [FiltersTypes.Boolean]: BooleanFilter,
    [FiltersTypes.Employee]: EmployeeFilter,
    [FiltersTypes.DateRange]: DateRangeFilter,
    [FiltersTypes.DateSegment]: DateSegmentFilter,
    [FiltersTypes.NumberRange]: NumberRangeFilter,
    [FiltersTypes.NestedSelect]: NestedSelectFilter,
    [FiltersTypes.ActivityPlace]: ActivityPlaceFilter,
    [FiltersTypes.UnpinnedSearch]: UnpinnedSearchFilter,
  };

  const { t } = useTranslation();
  const [isOpenFiltersMap, setIsOpenFiltersMap] = useState(false);

  const onFilterChangeHandler = (filter: string) => (updatedState: FiltersEntity[keyof FiltersEntity]) =>
    onFilterChange({
      updatedState,
      filter,
    });

  const onToggleFilter = (field: string) => () => toggleFilter(field);

  const onClickFilterOutside = (field: string) => () => closeFilter(field);

  const onRemoveFilter = (filter: string) => (updatedState: Parameters<typeof removeFilter>[0]['updatedState']) =>
    removeFilter({ filter, updatedState });

  const onToggleFilterMap = () => {
    return setIsOpenFiltersMap((prev) => !prev);
  };

  const onManageFiltersHandler = (id: string) => {
    addAndOpenFilter(id);
    setIsOpenFiltersMap(false);
  };

  const onClickOutsideFiltersManager = () => setIsOpenFiltersMap((prev) => !prev);

  const onSubmitFilterHandle = () => onSubmitFilter();

  const onResetFiltersHandle = () => {
    onResetFilters();
    setIsOpenFiltersMap(false);
  };

  if (!filters) return null;

  return (
    <Styled.Wrapper>
      <Styled.FiltersContainer>
        {Object.keys(filters).map((field) => {
          const Filter = FilterMap[filters[field].type];
          if (Filter && filters[field].active)
            return (
              <Filter
                {...filters[field]}
                {...filters[field].filterProps}
                onValueChange={onFilterChangeHandler(field)}
                onRemoveFilter={onRemoveFilter(field)}
                onToggleFilter={onToggleFilter(field)}
                onClickFilterOutside={onClickFilterOutside(field)}
                key={field}
              />
            );
          return null;
        })}
        <FiltersManager
          state={filters}
          isOpen={isOpenFiltersMap}
          onValueChange={onManageFiltersHandler}
          onToggleFilter={onToggleFilterMap}
          onRemoveFilter={onResetFilters}
          onClickFilterOutside={onClickOutsideFiltersManager}
          labelKey="buttons.more"
        />
      </Styled.FiltersContainer>
      <Styled.VerticalDivider />
      <Styled.ResetButton disabled={isDisabledFiltersButtons} onClick={onResetFiltersHandle} variant="text">
        {t('buttons.reset')}
      </Styled.ResetButton>
      <Styled.SubmitButton onClick={onSubmitFilterHandle}>{t('buttons.apply')}</Styled.SubmitButton>
      <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
        {withRecruiterMode && (
          <Styled.RecruiterModeContainer>
            <LabeledSwitch
              label={t('pages.candidates.allCandidatesTable.recruiterMode')}
              labelPlacement="start"
              checked={isRecruiterMode}
              onChange={handleChangeRecruiterMode}
            />
          </Styled.RecruiterModeContainer>
        )}
      </RoleGuard>
    </Styled.Wrapper>
  );
};

export default memo(DesktopView);
